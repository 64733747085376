//Action Types

import { NotificationManager } from "react-notifications";
import { getDispatchPromise, resolveDispatchPromise } from "../helpers/helpers";

export const API_GET_ALL_SERVICE_STATIONS = "API_GET_ALL_SERVICE_STATIONS";
export const STORE_ALL_SERVICE_STATIONS = "STORE_ALL_SERVICE_STATIONS";
export const API_EDIT_SERVICE_STATION = "API_EDIT_VEHICLE";
export const UPDATE_SERVICE_STATION = "UPDATE_SERVICE_STATION";
export const API_GET_SERVICE_STATION = "API_GET_SERVICE_STATION";
export const STORE_SERVICE_STATIONS = "STORE_SERVICE_STATIONS";

export const STORE_SERVICE_STATIONS_UPDATES = "STORE_SERVICE_STATIONS_UPDATES";

export const API_GET_LOCATIONS = "API_GET_LOCATIONS";
export const STORE_LOCATIONS = "STORE_LOCATIONS";

export const API_GET_REGIONS = "API_GET_REGIONS";
export const STORE_REGIONS = "STORE_REGIONS";
export const API_GET_DEALERS = "API_GET_DEALERS";
export const STORE_DEALERS = "STORE_DEALERS";

export const STORE_UPDATE_LOADING_STATUS = "STORE_UPDATE_LOADING_STATUS";

//Actions Creators

export const createStartLoadingAction = () => {
	return (dispatch) => {
		dispatch({
			type: STORE_UPDATE_LOADING_STATUS,
			skipLogging: true,
			payload: true,
		});
	};
};

export const createStopLoadingAction = () => {
	return (dispatch) => {
		dispatch({
			type: STORE_UPDATE_LOADING_STATUS,
			skipLogging: true,
			payload: false,
		});
	};
};

export const createGetAllServiceStationsAction = () => {
	return (dispatch) => {
		dispatch({
			type: API_GET_ALL_SERVICE_STATIONS,
			payload: {
				url: "/servicestations",
				onSuccess: store_all_service_stations,
				onError: get_service_stations_error,
			},
		});
	};
};

// Success/Error functions

const store_all_service_stations = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_ALL_SERVICE_STATIONS,
			skipLogging: true,
			payload: data,
		});
	};
};

const get_service_stations_error = (message) => {
	return (dispatch) => {
		NotificationManager.error(message);
	};
};

export const createGetServiceStationAction = (id) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_SERVICE_STATION,
			payload: {
				url: `/servicestations/getsingle?id=${id}`,
				onSuccess: store_service_station,
				onError: get_service_station_error,
				deferredPromiseResolvers,
			},
		});

		return dispatchPromise;
	};
};

const store_service_station = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: STORE_SERVICE_STATIONS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const get_service_station_error = (message) => {
	return (dispatch) => {
		NotificationManager.error(message);
	};
};

//Actions Creators
export const createEditServiceStationAction = (
	serviceStationModel,
	history
) => {
	return (dispatch) => {
		dispatch({
			type: API_EDIT_SERVICE_STATION,
			payload: {
				url: "/servicestations/edit-servicestation",
				method: "post",
				data: serviceStationModel,
				onSuccess: edit_service_station_success,
				onError: edit_service_stations_error,
				history: history,
			},
		});
	};
};

const edit_service_station_success = (data, history) => {
	return (dispatch) => {
		dispatch({
			type: STORE_SERVICE_STATIONS_UPDATES,
			skipLogging: true,
			payload: data,
		});
		history.push("../servicestation-list");
		NotificationManager.success("Service Station changed Successfully");
	};
};

const edit_service_stations_error = (message) => {
	return (dispatch) => {
		if (!message || message == "")
			message =
				"Error. Please review and ensure required fields are filled correctly";
		NotificationManager.error(message);
	};
};

//locations
export const createGetLocationsAction = () => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_LOCATIONS,
			payload: {
				url: "/servicestations/locations",
				onSuccess: store_locations,
				onError: get_locations_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

const store_locations = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_LOCATIONS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const get_locations_error = (message) => {
	return (dispatch) => {};
};

//regions
export const createGetRegionsAction = () => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_REGIONS,
			payload: {
				url: "/servicestations/regions",
				onSuccess: store_regions,
				onError: get_regions_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

const store_regions = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_REGIONS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const get_regions_error = (message) => {
	return (dispatch) => {};
};

//dealers
export const createGetAllDealersction = () => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_DEALERS,
			payload: {
				url: "/servicestations/dealers",
				onSuccess: store_dealers,
				onError: get_dealers_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

const store_dealers = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_DEALERS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const get_dealers_error = (message) => {
	return (dispatch) => {};
};

/**
 * initial vehicle list state
 */
const INIT_STATE = {
	serviceStations: [],
	selectedServiceStation: {},
	locations: [],
	regions: [],
	dealers: [],
	loading: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case STORE_UPDATE_LOADING_STATUS:
			return { ...state, loading: action.payload };

		case STORE_ALL_SERVICE_STATIONS:
			return { ...state, serviceStations: action.payload, loading: false };

		case STORE_SERVICE_STATIONS:
			return {
				...state,
				selectedServiceStation: action.payload,
				//loading: false,
			};

		case STORE_SERVICE_STATIONS_UPDATES:
			return {
				...state,
				selectedServiceStation: action.payload,
				//loading: false,
			};

		case STORE_LOCATIONS:
			return { ...state, locations: action.payload };

		case STORE_REGIONS:
			return { ...state, regions: action.payload };

		case STORE_DEALERS:
			return { ...state, dealers: action.payload };

		default:
			return { ...state };
	}
};


