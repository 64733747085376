/**
 * Orders Routes
 */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import PrivateRoute from "../../container/PrivateRoute";
// async components

import {
  AsyncOrdersListComponent,
  AsyncCreateOrderComponent,
  AsyncEditOrderComponent,
  AsyncOrderHistoryComponent,
} from "Components/AsyncComponent/AsyncComponent";

const Orders = ({ match }) => (
  <div className="content-wrapper">
    <Helmet>
      <title>Orders</title>
      <meta name="description" content="Order List" />
    </Helmet>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/order-list`} />
      <Route
        path={`${match.url}/create-order`}
        render={(props) => (
          <PrivateRoute
            {...props}
            claims={["Admin", "Dealer", "Clerk", "Operator"]}
            routecomponent={AsyncCreateOrderComponent}
          />
        )}
      />
      <Route
        path={`${match.url}/order-list`}
        render={(props) => (
          <PrivateRoute {...props} routecomponent={AsyncOrdersListComponent} />
        )}
      />
      <Route
        path={`${match.url}/order-history`}
        render={(props) => (
          <PrivateRoute
            {...props}
            routecomponent={AsyncOrderHistoryComponent}
          />
        )}
      />
      <Route
        path={`${match.url}/:toEdit`}
        render={(props) => (
          <PrivateRoute
            {...props}
            claims={["Admin", "Dealer", "Clerk", "Operator", "Supervisor"]}
            routecomponent={AsyncEditOrderComponent}
          />
        )}
      />
    </Switch>
  </div>
);

export default Orders;
