import axios from "axios";
import AppConfig from "../../../constants/AppConfig";
import download from "downloadjs";
import { LocalLogger, LOGGER_API_LOC } from "../logger/locallogger";

const api =
	({ dispatch }) =>
	(next) =>
	async (action) => {
		if (action.type.startsWith("API_") === false) return next(action);

		const {
			url,
			method,
			data,
			onSuccess,
			onError,
			history,
			deferredPromiseResolvers,
		} = action.payload;


		try {
			const response = action.type.startsWith("API_DOWNLOAD")
				? await axios.request({
						baseURL: AppConfig.apiBaseURL,
						url,
						method,
						data,
						responseType: "blob", // important
						withCredentials: true,
				  })
				: action.type.startsWith("API_LOGIN_REQUEST") 
				? await axios({
						baseURL: AppConfig.apiBaseURL,
						url,
						method,
						data,
						withCredentials: true,
				  }) 
				  : await axios({
						baseURL: AppConfig.apiBaseURL,
						url,
						method,
						data,
						withCredentials: true,
				  });

			if (action.type.startsWith("API_DOWNLOAD") == false) {
				if (typeof onSuccess === "function")
					dispatch(onSuccess(response.data, history, deferredPromiseResolvers));
				else
					dispatch({
						type: onSuccess,
						payload: response.data,
					});
			} else {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;

				let fileName = `${data.distributionCentreName}_${data.deliveryDate}.pdf`;

				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
			}
		} catch (err) {
			LocalLogger(err.response, LOGGER_API_LOC);
			let error =
				err.response && err.response.data
					? err.response.data.message
					: err.message;
			if (!error) error = "401";
			if (typeof onError === "function") dispatch(onError(error, history));
			else dispatch({ type: onError, payload: { message: error } });
			if (
				Array.isArray(deferredPromiseResolvers) &&
				deferredPromiseResolvers.length
			) {
				deferredPromiseResolvers[0].resolve("error");
			}
		}
	};

export default api;
