import axios from "axios";
import AppConfig from "../../../constants/AppConfig";

const logger = ({ dispatch }) => (next) => async (action) => {

  if(user && (!action.skipLogging) && action.type.startsWith("API_") == false)
  {
    const data = { 

            role: user.role, 
            timeoccurred: new Date().toLocaleString(),
            actiontype: action.type,
            actionpayload: JSON.stringify(action.payload), 
          };

      axios.request({
        baseURL: AppConfig.apiBaseURL,
        url: '/logger/logaction',
        method: 'POST',
        data,
        withCredentials: true,
        
      });
  }
  return next(action); 
};

export default logger;
