
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PrivateRoute from "../../container/PrivateRoute"
// async components

import {
    AsyncServiceStationListComponent, AsyncEditServiceStationComponent
} from 'Components/AsyncComponent/AsyncComponent';
 

const ServiceStations = ({ match }) => (
    <div className="content-wrapper">
        <Helmet>
            <title>Service Station</title>
            <meta name="description" content="Service Station List" />
        </Helmet>

        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/servicestation-list`} />
            <Route path={`${match.url}/servicestation-list`} render={(props) => <PrivateRoute {...props} claims={["Admin"]} routecomponent={AsyncServiceStationListComponent} />} />
            <Route path={`${match.url}/edit-servicestation`}   render={(props) => <PrivateRoute {...props}   routecomponent={AsyncEditServiceStationComponent} />} />
        </Switch>
    </div>
);


export default ServiceStations;
