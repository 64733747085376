/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from "react";
import Loadable from "react-loadable";

// rct page loader
import RctPageLoader from "Components/RctPageLoader/RctPageLoader";



/*---------------- Session ------------------*/


// Session Lock Screen
const AsyncSessionLockScreenComponent = Loadable({
  loader: () => import("Routes/session/lock-screen"),
  loading: () => <RctPageLoader />,
});

// Session Forgot Password
const AsyncSessionForgotPasswordComponent = Loadable({
  loader: () => import("Routes/session/forgot-password"),
  loading: () => <RctPageLoader />,
});

// Session Reset Password
const AsyncSessionResetPasswordComponent = Loadable({
  loader: () => import("Routes/session/reset-password"),
  loading: () => <RctPageLoader />,
});

// Session Page 404
const AsyncSessionPage404Component = Loadable({
  loader: () => import("Routes/session/404"),
  loading: () => <RctPageLoader />,
});

// Session Page 404
const AsyncSessionPage500Component = Loadable({
  loader: () => import("Routes/session/500"),
  loading: () => <RctPageLoader />,
});

// terms and condition
const AsyncTermsConditionComponent = Loadable({
  loader: () => import("Routes/pages/terms-condition"),
  loading: () => <RctPageLoader />,
});

// orders dashboard
const AsyncOrdersDashboardComponent = Loadable({
  loader: () => import("Routes/dashboard/orders"),
  loading: () => <RctPageLoader />,
});

// orders list
const AsyncOrdersListComponent = Loadable({
  loader: () => import("Routes/orders/order-list"),
  loading: () => <RctPageLoader />,
});

// Create Order
const AsyncCreateOrderComponent = Loadable({
  loader: () => import("Routes/orders/create-order"),
  loading: () => <RctPageLoader />,
});

// Edit Order
const AsyncEditOrderComponent = Loadable({
  loader: () => import("Routes/orders/edit-order"),
  loading: () => <RctPageLoader />,
});

const AsyncOrderHistoryComponent = Loadable({
  loader: () => import("Routes/orders/order-history"),
  loading: () => <RctPageLoader />,
});

const AsyncUsersListComponent = Loadable({
  loader: () => import("Routes/users/user-list"),
  loading: () => <RctPageLoader />,
});

const AsyncVehiclesListComponent = Loadable({
  loader: () => import("Routes/vehicles/vehicle-list"),
  loading: () => <RctPageLoader />,
});

const AsyncCreateVehicleComponent = Loadable({
  loader: () => import("Routes/vehicles/create-vehicle"),
  loading: () => <RctPageLoader />,
});

const AsyncCreateUserComponent = Loadable({
  loader: () => import("Routes/users/create-user"),
  loading: () => <RctPageLoader />,
});

const AsyncEditUserComponent = Loadable({
  loader: () => import("Routes/users/edit-user"),
  loading: () => <RctPageLoader />,
});

const AsyncUserProfileComponent = Loadable({
  loader: () => import("Routes/users/edit-user"),
  loading: () => <RctPageLoader />,
});

const AsyncSubmitEmailComponent = Loadable({
  loader: () => import("Routes/users/submit-email"),
  loading: () => <RctPageLoader />,
});

const AsyncEditVehicleComponent = Loadable({
  loader: () => import("Routes/vehicles/edit-vehicle"),
  loading: () => <RctPageLoader />,
});

const AsyncAuditLogListComponent = Loadable({
  loader: () => import("Routes/auditlogs/auditlog-list"),
  loading: () => <RctPageLoader />,
});


const AsyncAdminSettingsComponent = Loadable({
  loader: () => import("Routes/admin/settings"),
  loading: () => <RctPageLoader />,
});

const AsyncSchedulePlanComponent = Loadable({
  loader: () => import("Routes/schedules/plan"),
  loading: () => <RctPageLoader />,
});

const AsyncScheduleRouteComponent = Loadable({
  loader: () => import("Routes/schedules/scheduleroutes"),
  loading: () => <RctPageLoader />,
});

const AsyncScheduleDailyComponent = Loadable({
  loader: () => import("Routes/schedules/daily"),
  loading: () => <RctPageLoader />
});

const AsyncServiceStationListComponent = Loadable({
  loader: () => import("Routes/servicestation/servicestation-list"),
  loading: () => <RctPageLoader />,
});


const AsyncEditServiceStationComponent = Loadable({
  loader: () => import("Routes/servicestation/edit-servicestation"),
  loading: () => <RctPageLoader />,
});


export {
  AsyncSessionForgotPasswordComponent,
  AsyncSessionResetPasswordComponent,
  AsyncSessionLockScreenComponent,
  AsyncSessionPage404Component,
  AsyncSessionPage500Component,
  AsyncTermsConditionComponent,
  AsyncOrdersDashboardComponent,
  AsyncOrdersListComponent,
  AsyncCreateOrderComponent,
  AsyncEditOrderComponent,
  AsyncUsersListComponent,
  AsyncVehiclesListComponent,
  AsyncCreateVehicleComponent,
  AsyncCreateUserComponent,
  AsyncEditUserComponent,
  AsyncAuditLogListComponent,
  AsyncUserProfileComponent,
  AsyncAdminSettingsComponent,
  AsyncSchedulePlanComponent,
  AsyncScheduleRouteComponent,
  AsyncScheduleDailyComponent,
  AsyncOrderHistoryComponent,
  AsyncEditVehicleComponent,
  AsyncSubmitEmailComponent,
  AsyncServiceStationListComponent,
  AsyncEditServiceStationComponent,
};
