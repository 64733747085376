/**
 * Signin Firebase
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Button } from "reactstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import QueueAnim from "rc-queue-anim";

// components
import { SessionSlider } from "Components/Widgets";

// app config
import AppConfig from "Constants/AppConfig";

// redux action
import { createLoginAction } from "../store/authentication";

class Signin extends Component {
	state = {
		username: "",
		password: "",
	};

	constructor(props) {
		super(props);
		this.onUserLogin = this.onUserLogin.bind(this);
	}

	/**
	 * On User Login
	 */
	onUserLogin(event) {
		if (this.state.username !== "" && this.state.password !== "") {
			this.props.signin(this.state, this.props.history);
		}
		event.preventDefault();
	}

	render() {
		const { username, password } = this.state;
		const { loading } = this.props;
		return (
			<QueueAnim type="bottom" duration={2000}>
				<div className="rct-session-wrapper">
					{loading && <LinearProgress />}
					<AppBar position="static" className="session-header">
						<Toolbar>
							<div className="container">
								<div className="d-flex justify-content-between">
									<div className="session-logo">
										<Link to="/">
											<img
												src={AppConfig.appLogo}
												alt="session-logo"
												className="img-fluid"
												width="110"
												height="35"
											/>
										</Link>
									</div>
								</div>
							</div>
						</Toolbar>
					</AppBar>
					<div className="session-inner-wrapper p-4 h-100 p-md-0">
						<div className="row">
							<div className="col-sm-8 col-lg-5 mx-auto">
								<div className="session-body text-center">
									<div className="session-head mb-30">
										<h2 className="font-weight-bold">
											Please login to the NP Portal
										</h2>
									</div>
									<Form onSubmit={this.onUserLogin}>
										<FormGroup className="has-wrapper">
											<Input
												type="mail"
												value={username}
												name="user-mail"
												id="user-mail"
												className="has-input input-lg"
												placeholder="Enter Username"
												onChange={(event) =>
													this.setState({ username: event.target.value })
												}
											/>
											<span className="has-icon">
												<i className="ti-email"></i>
											</span>
										</FormGroup>
										<FormGroup className="has-wrapper">
											<Input
												value={password}
												type="Password"
												name="user-pwd"
												id="pwd"
												className="has-input input-lg"
												placeholder="Password"
												onChange={(event) =>
													this.setState({ password: event.target.value })
												}
											/>
											<span className="has-icon">
												<i className="ti-lock"></i>
											</span>
										</FormGroup>
										<FormGroup className="mb-15">
											<Button
												color="danger"
												className="btn-block text-white w-100"
												variant="contained"
												size="large"
												//onClick={(event) => this.onUserLogin(event)}
											>
												Sign In
											</Button>
										</FormGroup>
									</Form>

									<p className="mb-0">
										<a href="/forgot-password" className="text-muted">
											Forgot Password
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</QueueAnim>
		);
	}
}

// map state to props
const mapStateToProps = ({ authentication }) => {
  const { user } = authentication;
  return { user };
};

export default connect(mapStateToProps, {
  signin: createLoginAction,
})(Signin);
