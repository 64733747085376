import React, { Component,Fragment } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";

class PrivateAccess extends Component{
  
    constructor(props)
    {
      super(props);
    }

    isLoggedIn( user, loggedIn) {
      return user && loggedIn;
    }

    hasClaim(userClaims, claims)
    {
      if(!claims) return true;
      if(!userClaims) return false;
      const normalizedUserClaims = Array.isArray(userClaims) ? userClaims.map((claim) => {return claim.toLowerCase()}) : userClaims.split().map((claim) => {return claim.toLowerCase()});
      const normalizedClaims = Array.isArray(claims) ? claims.map((claim) => {return claim.toLowerCase()}) : claims.split().map((claim) => {return claim.toLowerCase()});
      if(normalizedClaims.length === 0) return true;
      return normalizedUserClaims.some(item => normalizedClaims.includes(item));
    }

    render(){
        const { user, loggedIn, children, checkClaim, claims, replaceWith } = this.props;       
        if(replaceWith) 
        return(
          <Fragment>{this.isLoggedIn(user, loggedIn) && (!checkClaim || (this.hasClaim(user.role, claims)))? children : replaceWith}</Fragment>   
        )
        else
        return(
          <Fragment>{this.isLoggedIn(user, loggedIn) && (!checkClaim || (this.hasClaim(user.role, claims))) && children}</Fragment>   
        )
    }
}

PrivateAccess.propTypes = {
  children: PropTypes.element.isRequired,
  checkClaim: PropTypes.bool.isRequired,
  claims: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  replaceWith:PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),

}

// map state to props
const mapStateToProps = ({ authentication }) => {
  const { user, loggedIn } = authentication;
  return { user, loggedIn };
};

export default connect(mapStateToProps, {})(PrivateAccess);