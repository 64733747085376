/**
 * App.js Layout Start Here
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import ServerNotificationPoller from "./ServerNotificationPoller";
import ApplicationSettings from "./ApplicationSettings";

// rct theme provider
import RctThemeProvider from "./RctThemeProvider";

//Main App
import RctDefaultLayout from "./DefaultLayout";

// app signin
import AppSignIn from "./Signin";

// async components
import {
  AsyncSessionForgotPasswordComponent,
  AsyncSessionResetPasswordComponent,
} from "Components/AsyncComponent/AsyncComponent";

//Auth0
import Auth from "../Auth/Auth";

// callback component
import Callback from "Components/Callback/Callback";

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};

/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const InitialPath = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  render() {
    const { location, match, user } = this.props;
      if (location.pathname === "/") {
        if (!user) {
          return <Redirect to={"/signin"} />;
        } else if (user.role == "Admin"){
          return <Redirect to={"/app/dashboard/orders"} />;
        }
        else{
          return <Redirect to={"/app/orders/order-list"} />;
        }
      }
      return (
        <RctThemeProvider>
          <NotificationContainer />
          <ServerNotificationPoller />
          <ApplicationSettings />
          <InitialPath
            path={`${match.url}app`}
            authUser={user}
            component={RctDefaultLayout}
          />
          <Route path="/signin" component={AppSignIn} />
          <Route
            path="/forgot-password"
            component={AsyncSessionForgotPasswordComponent}
          />
          <Route
            path="/reset-password/:username/:token"
            component={AsyncSessionResetPasswordComponent}
          />
          <Route
            path="/callback"
            render={(props) => {
              handleAuthentication(props);
              return <Callback {...props} />;
            }}
          />
        </RctThemeProvider>
      );
  }
}

// map state to props
const mapStateToProps = ({ authentication }) => {
  const { user } = authentication;
  return { user };
};

export default connect(mapStateToProps)(App);
