/**
 * Todo App Actions
 */
import api from "Api";
import { NP_GET_ORDERS } from "Actions/types";

/**
 * Redux Action Get Todos
 */
export const getAllOrders = () => (dispatch) => {
  api
    .get("orders")
    .then((response) => {
      dispatch({ type: NP_GET_ORDERS, payload: response.data });
    })
    .catch((errors) => {
      // error handling
    });
};

/**
 * Redux Action To Fetch To Todos
 */
export const fetchTodos = () => ({
  type: FETCH_ALL_ORDERS,
});
