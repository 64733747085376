/**
 * Users Routes
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PrivateRoute from "../../container/PrivateRoute"
// async components

import {
  AsyncScheduleRouteComponent,
  AsyncSchedulePlanComponent,
  AsyncScheduleDailyComponent
} from 'Components/AsyncComponent/AsyncComponent';


const Schedules = ({ match }) => (
	<div className="content-wrapper">
		<Helmet>
			<title>Schedules</title>
			<meta name="description" content="Schedules" />
		</Helmet>

		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/plan`} />
			<Route
				path={`${match.url}/daily`}
				render={(props) => (
					<PrivateRoute
						{...props}
						claims={["Admin", "Contractor", "Clerk", "Supervisor", "Driver"]}
						routecomponent={AsyncScheduleDailyComponent}
					/>
				)}
			/>
			<Route
				path={`${match.url}/plan`}
				render={(props) => (
					<PrivateRoute
						{...props}
						claims={["Admin", "Contractor", "Clerk", "Supervisor", "Driver"]}
						routecomponent={AsyncSchedulePlanComponent}
					/>
				)}
			/>
			<Route
				path={`${match.url}/routes`}
				render={(props) => (
					<PrivateRoute
						{...props}
						claims={["Admin", "Contractor", "Clerk", "Supervisor", "Driver"]}
						routecomponent={AsyncScheduleRouteComponent}
					/>
				)}
			/>
		</Switch>
	</div>
);

export default Schedules;