//Action Types

import { NotificationManager } from "react-notifications";

export const API_GET_ALL_VEHICLES = "API_GET_ALL_VEHICLES";
export const API_GET_ALL_VEHICLE_TYPES = "API_GET_ALL_VEHICLE_TYPES";
export const STORE_ALL_VEHICLES = "STORE_ALL_VEHICLES";
export const STORE_ALL_VEHICLE_TYPES = "STORE_ALL_VEHICLE_TYPES";
export const API_ADD_VEHICLE = "API_ADD_VEHICLE";
export const API_GET_VEHICLE = "API_GET_VEHICLE";
export const STORE_VEHICLE = "STORE_VEHICLE";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";

export const API_EDIT_VEHICLE = "API_EDIT_VEHICLE";

export const API_DELETE_VEHICLE = "API_DELETE_VEHICLE";
export const REMOVE_VEHICLE_ENTRY = "REMOVE_VEHICLE_ENTRY";
export const SHOW_DELETED = "SHOW_DELETED";
export const SET_TO_DELETE = "SET_TO_DELETE";

export const API_GET_ALL_DEPOTS = "API_GET_ALL_DEPOTS";
export const STORE_ALL_DEPOTS = "STORE_ALL_DEPOTS";

//Actions Creators
export const createGetAllVehiclesAction = () => {
    return (dispatch) => {
        dispatch({
            type: API_GET_ALL_VEHICLES,
            payload: {
                url: "/vehicles",
                onSuccess: store_all_vehicles,
                onError: get_vehicles_error,
            },
        });
    };
};

export const createGetAllVehicleTypesAction = () => {
    return (dispatch) => {
        dispatch({
            type: API_GET_ALL_VEHICLE_TYPES,
            payload: {
                url: "/vehicles/GetAllVehicleTypes",
                onSuccess: store_all_vehicle_types,
                onError: () => {},
            },
        });
    };
};


export const createAddVehicleAction = (vehicle, _history) => {
    return (dispatch) => {
        dispatch({
            type: API_ADD_VEHICLE,
            payload: {
                url: "/vehicles/create-vehicle",
                method: "post",
                data: vehicle,
                history: _history,
                onSuccess: create_vehicle_success,
                onError: create_vehicle_error,
            },
        });
    };
};


export const createGetVehicleAction = (id) => {
    
    return (dispatch) => {
        dispatch({
            type: API_GET_VEHICLE,
            payload: {
                url: `/vehicles/get-vehicle/${id}`,
                onSuccess: get_vehicle_success,
                onError: () => {},
            },
        });
    };
};



export const editVehicleFormAction = (vehicle, _history) => {
    return (dispatch) => {
        dispatch({
            type: API_EDIT_VEHICLE,
            payload: {
                url: "/vehicles/edit-vehicle",
                method: "post",
                data: vehicle,
                history: _history,
                onSuccess: edit_vehicle_success,
                onError: edit_vehicle_error,
            },
        });
    };
};


export const editVehiclePropertiesAction = (model) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_VEHICLE,
            payload: model
        });
    }
}


export const deleteVehicleAction = (vehicle, history) => {
    return (dispatch) => {
        dispatch({
            type: API_DELETE_VEHICLE,
            payload: {
                url: `/vehicles/delete-vehicle/${vehicle}`,
                method: "post",
                data: vehicle,
                history: history,
                onSuccess: delete_vehicle_success,
                onError: delete_vehicle_error,
            },
        });
    };
};

export const createShowDeletedVehicles = (state) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_DELETED,
            payload: {
                toggle: state
            },
        });
    };
};

export const setVehicleToDelete = (vehicle) => {
    return (dispatch) => {
        dispatch({
            type: SET_TO_DELETE,
            payload: {
                vehicle: vehicle
            },
        });
    };
};

// Success/Error functions

const store_all_vehicles = (data) => {
    return (dispatch) => {
        dispatch({
            type: STORE_ALL_VEHICLES,
            skipLogging: true,
            payload: data
        });
    };
};


const get_vehicles_error = (message) => {
    return (dispatch) => {
        NotificationManager.error(message);
    };
};


const store_all_vehicle_types = (data) => {
    return (dispatch) => {
        dispatch({
            type: STORE_ALL_VEHICLE_TYPES,
            skipLogging: true,
            payload: data
        });
    };
};



const create_vehicle_success = (data, history) => {
    return (dispatch) => {
        history.push("../vehicle-list");
        NotificationManager.success("Vehicle Added Successfully");
    };
};

const create_vehicle_error = (message) => {
    return (dispatch) => {
        if(!message||message=="") message="Error. Please review and ensure required fields are filled correctly";
        NotificationManager.error(message);

    };
};


const get_vehicle_success = (data, history) => {
    return (dispatch) => {
        dispatch({
            type: STORE_VEHICLE,
            skipLogging: true,
            payload: data
        });
    };
};

const edit_vehicle_success = (data, history) => {
    return (dispatch) => {
        history.push("../vehicle-list");
        NotificationManager.success("Vehicle changed Successfully");
        
    };
};

const edit_vehicle_error = (message) => {
    return (dispatch) => {
        if(!message||message=="") message="Error. Please review and ensure required fields are filled correctly";
        NotificationManager.error(message);
    };
};

const delete_vehicle_success = (data, history) => {
    return (dispatch) => {
        dispatch({ type: REMOVE_VEHICLE_ENTRY, skipLogging: true, payload: { id: data.id } });
        NotificationManager.success("Vehicle Deleted Successfully");


    };
};

const delete_vehicle_error = (message) => {
    return (dispatch) => {
        NotificationManager.error(message);
    };
};

export const createGetAllDepotsAction = () => {
    return (dispatch) => {
        dispatch({
            type: API_GET_ALL_DEPOTS,
            payload: {
                url: "/vehicles/GetAllDepots",
                onSuccess: store_all_depots,
                onError: get_depots_error,
            },
        });
    };
};


const store_all_depots = (data) => {
    return (dispatch) => {
        dispatch({
            type: STORE_ALL_DEPOTS,
            skipLogging: true,
            payload: data
        });
    };
};


const get_depots_error = (message) => {
    return (dispatch) => {
        NotificationManager.error(message);
    };
};



/**
 * initial vehicle list state
 */
const INIT_VEHICLE_LIST_STATE = {
    vehicles: [],
    selectedVehicle:{}, 
    deleted: null,
    loading: false,
};

export default (state = INIT_VEHICLE_LIST_STATE, action) => {
    switch (action.type) {

        case STORE_ALL_VEHICLES:
            return { ...state, vehicles: action.payload, loading: false };

        case STORE_ALL_VEHICLE_TYPES:
            return { ...state, vehicleTypes: action.payload, loading: false };

        case STORE_VEHICLE:
            return { ...state, selectedVehicle: action.payload, loading: false };

        case UPDATE_VEHICLE:
            return { ...state, selectedVehicle: action.payload, loading: false };

        case SHOW_DELETED:
            return { ...state, showDeleted: !action.payload.toggle };

        case SET_TO_DELETE:
            return { ...state, vehicleToDelete: action.payload.vehicle };

        case REMOVE_VEHICLE_ENTRY:
            return { ...state, vehicles: state.vehicles.map((vehicle) => { if (vehicle.id == action.payload.id) vehicle.deleted = true; return vehicle; }), loading: false, alertDeleted: true };
        
        case STORE_ALL_DEPOTS:
            return { ...state, depots: action.payload, loading: false, alertDeleted: true };

        default:
            return { ...state, loading: false };
    }
};
