import { NotificationManager } from "react-notifications";
import * as helpers from "../helpers/helpers";
//Action Types
export const API_LOGIN_REQUEST = "API_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const API_LOG_LOGIN_SUCCESS = "export const";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const API_LOGOUT_REQUEST = "API_LOGOUT_REQUEST";
export const API_LOGIN_SUCCESS = "API_LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const PROCESS_LOGOUT = "PROCESS_LOGOUT";


export const API_FORGOT_PASSWORD = "API_FORGOT_PASSWORD";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const API_RESET_PASSWORD = "API_RESET_PASSWORD";

export const API_GET_PASSWORD_REQUIREMENTS = "API_GET_PASSWORD_REQUIREMENTS";
export const PASSWORD_REQUIREMENT_SUCCESS = "PASSWORD_REQUIREMENT_SUCCESS";


export const API_GET_LOGGED_IN_USER = "API_GET_LOGGED_IN_USER";
export const REFRESH_USER_SUCCESS = "REFRESH_USER_SUCCESS";

export const REFRESH_USER_FAILURE = "REFRESH_USER_SUCCESS";
//Actions Creators
//TODO: Make onSuccess and onError an array of actions to handle doing notifications as well as updating the store
export const createLoginAction = (user, history) => {
  return (dispatch) => {
    const { username, password } = user;
    dispatch({
      type: API_LOGIN_REQUEST,
      payload: {
        url: "/authenticate/login",
        method: "post",
        data: { username, password },
        history: history,
        onSuccess: login,
        onError: loginError,
      },
    });
  };
};

const login = (data, history) => {
  return (dispatch) => {
    const user = {...data};

    dispatch({
      type: API_LOGIN_SUCCESS,
      payload: {
        url: "/logger/logaction",
        method: "POST",
        data: { 
            username: user.username, 
            role: user.role, 
            timeoccurred: new Date().toLocaleString(),
            actiontype: "LOGIN_SUCCESS",
            actionpayload: "", 
        },
        history: history,
        onSuccess: () => {},
        onError: () => {},
      },
    });

    dispatch({ type: LOGIN_SUCCESS, payload: user });
    history.push("/");
    NotificationManager.success("User Login Successfully!");
  };
};

const loginError = (message) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_FAILURE });
    NotificationManager.error(loginErrorMessage(message));
  };
};

const loginErrorMessage = (message) => {
  const messagelc = message.toLowerCase();
  if (messagelc.includes("network")) return "Login Error: Unable to establish a connection to server";
  if (messagelc.includes("401"))
    return "Your username and/or password do not match";
  if (messagelc.includes("403")) return "Your are not authorized to login";
  return "Unable to login";
};

//logout
export const createLogoutAction = (history, user) => {
  return (dispatch) => {
    const { username, password } = user;
    dispatch({
      type: API_LOGOUT_REQUEST,
      payload: {
        url: "/authenticate/logout",
        method: "get",
        data:  username,
        history: history,
        onSuccess: logout,
        onError: logout,
      },
    });
    dispatch({
      type: API_LOGOUT_REQUEST,
      payload: {
        url: "/logger/logaction",
        method: "POST",
        data: { 
            username: user.username, 
            role: user.role, 
            timeoccurred: new Date().toLocaleString(),
            actiontype: "LOGOUT",
            actionpayload: "", 
        },
        history: history,
        onSuccess: () => {},
        onError: () => {},
      },
    });
  };
};

const logout = (data, history) => {
  return (dispatch) => {
    
    
    // remove user from local storage to log user out
    dispatch({ type: PROCESS_LOGOUT });
    history.push("/signin");
    NotificationManager.success("User Logout Successfully");
  };
};


const loginLog = (data, history) => {
  return (dispatch) => {
    
  };
};


export const createRefreshLoggedInUser = () => {
  return (dispatch) => {
    dispatch({
      type: API_GET_LOGGED_IN_USER,
      payload: {
        url: "/authenticate/getLoggedInUser",
        method: "GET",
        onSuccess: refreshUser,
        onError: refreshUserError,
      },
    });
  };
};


const refreshUser = (data, history) => {
  return (dispatch) => {
    const user = {...data};
    dispatch({ type: REFRESH_USER_SUCCESS, payload: user });
  };
};

const refreshUserError = (data, history) => {
  return (dispatch) => {
    dispatch({ type: REFRESH_USER_FAILURE });
    NotificationManager.error(loginErrorMessage(message));
  };
};



//forgot password
export const createForgotPasswordAction = (username) => {
  return (dispatch) => {
    //const uname = username;
    dispatch({
      type: API_FORGOT_PASSWORD,
      payload: {
        url: "/authenticate/forgotpassword",
        method: "post",
        //history: history,
        data: { username },
        onSuccess: forgotPassword,
        onError: errrorForgotPassword,
      },
    });
  };
};

const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD, payload: data  });
    NotificationManager.success("Password reset email sent, check your email");
  };
};


const errrorForgotPassword = (data) => {
  return (dispatch) => {
    NotificationManager.success("Failed to send password reset email, try again!");
  };
};

//reset password

export const createResetPasswordAction = ({
  username,
  password,
  confirmPassword,
  token,
  history,
}) => {
  return (dispatch) => {
    dispatch({
      type: API_RESET_PASSWORD,
      payload: {
        url: "/authenticate/resetpassword",
        method: "post",
        history: history,
        data: { username, password, confirmPassword, token },
        onSuccess: resetPassword,
        onError: resetPasswordError,
      },
    });
  };
};

const resetPassword = (data, history) => {
  return (dispatch) => {
    dispatch({ type: LOGOUT_SUCCESS });
    history.push("/signin");
    NotificationManager.success("Password Reset Successfully");
  };
};

const resetPasswordError = (data) => {
  return (dispatch) => {
    NotificationManager.error("Failed to Reset Password");
  };
};




export const createGetPasswordRequirementAction = () => {
  return (dispatch) => {
    dispatch({
      type: API_GET_PASSWORD_REQUIREMENTS,
      payload: {
        url: "/authenticate/password-requirements",
        method: "get",
        onSuccess: handlePasswordRequirement,
        onError: () => {},
      },
    });
  };
};


const handlePasswordRequirement = (data) => {
  return (dispatch) => {
    dispatch({ type: PASSWORD_REQUIREMENT_SUCCESS, payload: data  });
  };
};




const INIT_SETTINGS_STATE = {
  loading: false,
  user: {}
};

export default (state = INIT_SETTINGS_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.payload,
      };

    case LOGIN_FAILURE:
      return { loggedIn: false, user: null };

    case LOGOUT_SUCCESS:
      return { loggedIn: false, user: null };

    case REFRESH_USER_SUCCESS:
      return { loggedIn: true, user:  action.payload, };

    case REFRESH_USER_FAILURE:
      return {  loggedIn: false, user:  null, };



    case FORGOT_PASSWORD:
      return {...state, forgotpasswordusername: "", resetEmailSent:true};

    case PASSWORD_REQUIREMENT_SUCCESS:
        return {...state, passwordrequirements:  action.payload };

    default:
      return state;
  }
};
