import { createStore, applyMiddleware, compose } from "redux";
import Thunk from "redux-thunk";
import reducers from "./AllReducers";
import * as middleware from "./middleware";
import {loadUserState, saveUserState} from "../helpers/helpers"

export function configureStore(initialState) {
	const middlewares = [];
	middlewares.push(Thunk);
	//middlewares.push(middleware.logger);
	middlewares.push(middleware.api);

	const persistedUserState = loadUserState();
	const store = createStore(
		reducers,
		{...initialState, authentication: {...persistedUserState}},

		process.env.NODE_ENV === `development`
			? compose(
					applyMiddleware(...middlewares),
					window.__REDUX_DEVTOOLS_EXTENSION__
						? window.__REDUX_DEVTOOLS_EXTENSION__()
						: (f) => f
			  )
			: compose(applyMiddleware(...middlewares))
	);

	store.subscribe(() => {
		const auth = store.getState().authentication;
		if(auth.loggedIn)
			saveUserState(auth);
	});

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept("./AllReducers", () => {
			const nextRootReducer = require("./AllReducers");
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
}
