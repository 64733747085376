import Orders from "Routes/orders";
import Users from "Routes/users";
import Vehicles from "Routes/vehicles";
import AuditLogs from "Routes/auditlogs";
import Dashboard from "Routes/dashboard";
import Admin from "Routes/admin";
import Schedules from "Routes/schedules";
import ServiceStations from "Routes/servicestation";

export default [
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "orders",
    component: Orders,
  },
  {
    path: "vehicles",
    component: Vehicles,
  },
  {
    path: "servicestations",
    component: ServiceStations,
  },
  {
    path: "users",
    component: Users,
  },
  {
    path: "auditlogs",
    component: AuditLogs,
  },
  {
    path: "schedules",
    component: Schedules
  },
  {
    path: "admin",
    component: Admin,
  }
];
