import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createGetAllNotificationsAction } from "../store/notification";

class ServerNotificationPoller extends Component {
  state = {
    delay: 10000,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.interval = setInterval(this.getNotifications, this.state.delay);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.delay !== this.state.delay) {
      clearInterval(this.interval);
      this.interval = setInterval(this.getNotifications, this.state.delay);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getNotifications = () => {
    const pathname = window.location.pathname ?? "";
    if (
      !pathname.includes("/reset-password") &&
      !pathname.includes("/signin")
    ) {
      if (this.props.user && this.props.loggedIn)
        this.props.createGetAllNotificationsAction(
          this.props.user.id,
          this.props.history
        );
    }
  };

  render() {
    return "";
  }
}

function mapStateToProps(state) {
  let user = null;
  let loggedIn = false;
  if (state.authentication) {
    user = state.authentication.user;
    loggedIn = state.authentication.loggedIn;
  }

  return { user, loggedIn };
}

export default withRouter(
  connect(mapStateToProps, { createGetAllNotificationsAction })(
    ServerNotificationPoller
  )
);
