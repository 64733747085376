import { getDispatchPromise, resolveDispatchPromise } from "../helpers/helpers";

//Action Types

import { NotificationManager } from "react-notifications";

export const API_GET_ALL_USERS = "API_GET_ALL_USERS";
export const API_GET_ALL_ROLES = "API_GET_ALL_ROLES";
export const STORE_ALL_USERS = "STORE_ALL_USERS";
export const STORE_ALL_ROLES = "STORE_ALL_ROLES";
export const API_CREATE_USER = "API_CREATE_USER";
export const API_DELETE_USER = "API_DELETE_USER";
export const API_RESTORE_USER = "API_RESTORE_USER";
export const API_EDIT_USER = "API_EDIT_USER";
export const REMOVE_USER_ENTRY = "REMOVE_USER_ENTRY";
export const RESTORE_USER_ENTRY = "RESTORE_USER_ENTRY";
export const SHOW_DELETED = "SHOW_DELETED";
export const SET_TO_DELETE = "SET_TO_DELETE";
export const SET_TO_RESTORE = "SET_TO_RESTORE";
export const API_GET_ROLE = "API_GET_ROLE";
export const STORE_ROLE = "STORE_ROLE";

export const API_GET_DEALER_SERVICE_STATIONS =
	"API_GET_DEALER_SERVICE_STATIONS";
export const STORE_DEALER_SERVICE_STATIONS = "STORE_DEALER_SERVICE_STATIONS";

export const API_GET_DEALER_SERVICE_STATIONS_WITH_OPERATORS =
	"API_GET_DEALER_SERVICE_STATIONS_WITH_OPERATORS";
export const STORE_DEALER_SERVICE_STATIONS_WITH_OPERATORS =
	"STORE_DEALER_SERVICE_STATIONS_WITH_OPERATORS";

export const API_GET_ALL_REGIONS = "API_GET_ALL_REGIONS";
export const STORE_ALL_REGIONS = "STORE_ALL_REGIONS";

export const API_USERNAME_EXISTS = "API_USERNAME_EXISTS";
export const STORE_USERNAME_EXISTS = "STORE_USERNAME_EXISTS";

export const API_GET_USER = "API_GET_USER";
export const STORE_USER = "STORE_USER";
export const STORE_CURRENT_USER = "STORE_CURRENT_USER";
export const RESET_SELECTED_USER = "RESET_SELECTED_USER";

export const API_GET_LINKED_STATION_ACCOUNTS =
	"API_GET_LINKED_STATION_ACCOUNTS";
export const STORE_LINKED_STATION_ACCOUNTS = "STORE_LINKED_STATION_ACCOUNTS";

export const API_GET_SERVICE_STATIONS_TO_LINK =
	"API_GET_SERVICE_STATIONS_TO_LINK";
export const STORE_ALL_SERVICE_STATIONS = "STORE_ALL_SERVICE_STATIONS";

export const DISMISS_EMAIL_PROMPT = "DISMISS_EMAIL_PROMPT";

export const STORE_UPDATE_LOADING_STATUS = "STORE_UPDATE_LOADING_STATUS";

//Actions Creators

export const createStartLoadingAction = () => {
	return (dispatch) => {
		dispatch({
			type: STORE_UPDATE_LOADING_STATUS,
			skipLogging: true,
			payload: true,
		});
	};
};

export const createStopLoadingAction = () => {
	return (dispatch) => {
		dispatch({
			type: STORE_UPDATE_LOADING_STATUS,
			skipLogging: true,
			payload: false,
		});
	};
};

export const createGetServiceStationsToLink = (userId) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_SERVICE_STATIONS_TO_LINK,
			payload: {
				url: `/users/GetServiceStationsToLink?id=${userId}`,
				method: "get",
				onSuccess: storeAllServiceStations,
				onError: errorFetchingServiceStations,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const createGetAllUsersAction = () => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_ALL_USERS,
			payload: {
				url: "/users",
				onSuccess: store_all_users,
				onError: get_users_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const createGetAllRolesAction = () => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_ALL_ROLES,
			payload: {
				url: "/users/GetUserRoles",
				onSuccess: store_all_roles,
				onError: () => {},
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const createGetAllRegionsAction = () => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_ALL_REGIONS,
			payload: {
				url: "/users/GetAllRegions",
				onSuccess: store_all_regions,
				onError: () => {},
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const createCreateUserFormAction = (actor, _history) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_CREATE_USER,
			payload: {
				url: "/users/add-user",
				method: "post",
				data: actor,
				history: _history,
				onSuccess: create_user_success,
				onError: create_user_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const deleteUserAction = (user, history) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_DELETE_USER,
			payload: {
				url: `/users/delete-user/${user}`,
				method: "post",
				data: user,
				history: history,
				onSuccess: delete_user_success,
				onError: delete_user_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const restoreUserAction = (user, history) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_RESTORE_USER,
			payload: {
				url: `/users/restore-user/${user}`,
				method: "post",
				data: user,
				history: history,
				onSuccess: restore_user_success,
				onError: restore_user_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};


export const createShowDeletedUsers = (state) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_DELETED,
			payload: {
				toggle: state,
			},
		});
	};
};

export const setUserToDelete = (user) => {
	return (dispatch) => {
		dispatch({
			type: SET_TO_DELETE,
			payload: {
				user: user,
			},
		});
	};
};

export const setUserToRestore = (user) => {
	return (dispatch) => {
		dispatch({
			type: SET_TO_RESTORE,
			payload: {
				user: user,
			},
		});
	};
};

export const editUserFormAction = (actor, _history) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_EDIT_USER,
			payload: {
				url: "/users/edit-user",
				method: "post",
				data: actor,
				history: _history,
				onSuccess: edit_user_success,
				onError: edit_user_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const createGetRoleAction = (id) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_ROLE,
			payload: {
				url: `/users/get-user-role/${id}`,
				onSuccess: get_role_success,
				onError: () => {},
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

/**This is a redux action that gets the account ids for all accounts associated with a dealer and
 * stores it in the redux store
 * @param {number} id - The id if the dealer to get the accounts for
 */
export const createGetDealerServiceStations = (id) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_DEALER_SERVICE_STATIONS,
			payload: {
				url: `/users/get-station-dealer-ids-by-id/${id}`,
				onSuccess: get_dealer_service_stations_success,
				onError: get_dealer_service_stations_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

/**This is a redux action that gets the account ids for all accounts associated with a dealer
 * only if those association also has an operator and stores it in the redux store
 * @param {number} id - The id if the dealer to get the accounts for
 */
export const createGetDealerServiceStationsWithOperators = (id) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_DEALER_SERVICE_STATIONS_WITH_OPERATORS,
			payload: {
				url: `/users/get-station-dealers-with-operator-ids-by-id/${id}`,
				onSuccess: get_dealer_service_stations_with_operators_success,
				onError: get_dealer_service_stations_with_operators_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

/**A redux action that gets the id's of all active and linked (linked to a dealer) accounts (accounts are aka service stations)
 * and stores it in the store
 */
export const createGetLinkedServiceStationAccounts = () => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_LINKED_STATION_ACCOUNTS,
			payload: {
				url: `/users/GetLinkedServiceStationAccounts`,
				onSuccess: get_linked_station_accounts_success,
				onError: get_linked_station_accounts_error,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const createUsernameExistsAction = (name) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_USERNAME_EXISTS,
			payload: {
				url: `/users/username-exists/${name}`,
				onSuccess: username_exists_success,
				onError: () => {},
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const createGetUserAction = (id) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_USER,
			payload: {
				url: `/users/get-user/${id}`,
				onSuccess: get_user_success,
				onError: () => {},
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const createGetCurrentUserAction = (id) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_USER,
			payload: {
				url: `/users/get-user/${id}`,
				onSuccess: get_current_user_success,
				onError: () => {},
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

export const createDismissEmailPrompt = () => {
	return (dispatch) => {
		dispatch({
			type: DISMISS_EMAIL_PROMPT,
			skipLogging: true,
			// payload: data
		});
	};
};

// Success/Error functions

const store_all_users = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: STORE_ALL_USERS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const get_users_error = (message) => {
	return (dispatch) => {
		NotificationManager.error(message);
	};
};

const store_all_regions = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: STORE_ALL_REGIONS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const store_all_roles = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: STORE_ALL_ROLES,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const create_user_success = (data, history) => {
	return (dispatch) => {
		history.push("../user-list");
		NotificationManager.success("User Added Successfully");
	};
};

const create_user_error = (message) => {
	return (dispatch) => {
		if (!message || message == "")
			message =
				"Error. Please review and ensure required fields are filled correctly";
		NotificationManager.error(message);
	};
};

const delete_user_success = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: REMOVE_USER_ENTRY,
			skipLogging: true,
			payload: { userId: data.applicationUserId },
		});
		resolveDispatchPromise(deferredPromiseResolvers);
		NotificationManager.success("User Disabled Successfully");
	};
};

const restore_user_success = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: RESTORE_USER_ENTRY,
			skipLogging: true,
			payload: { userId: data.applicationUserId },
		});
		resolveDispatchPromise(deferredPromiseResolvers);
		NotificationManager.success("User Restored Successfully");
	};
};

const delete_user_error = (message) => {
	return (dispatch) => {
		NotificationManager.error(message);
	};
};

const restore_user_error = (message) => {
	return (dispatch) => {
		NotificationManager.error(message);
	};
};

const get_role_success = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: STORE_ROLE,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const get_user_success = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: STORE_USER,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const get_current_user_success = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: STORE_CURRENT_USER,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const edit_user_success = (data, history) => {
	return (dispatch) => {
		if (
			history.id ||
			(history.location.state && history.location.state.isCurrentUser)
		) {
			//for email submissions or user profile changes
			dispatch({
				type: STORE_CURRENT_USER,
				skipLogging: true,
				payload: data,
			});
		}
		if (history.id) NotificationManager.success("E-mail set Successfully");
		else NotificationManager.success("User changed Successfully");
		dispatch({
			type: RESET_SELECTED_USER,
			skipLogging: true,
		});
		if (history.push) history.push("../user-list");
	};
};

const edit_user_error = (message) => {
	return (dispatch) => {
		if (!message || message == "")
			message =
				"Error. Please review and ensure required fields are filled correctly";
		NotificationManager.error(message);
	};
};

const get_dealer_service_stations_success = (
	data,
	history,
	deferredPromiseResolvers
) => {
	return (dispatch) => {
		dispatch({
			type: STORE_DEALER_SERVICE_STATIONS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const get_dealer_service_stations_with_operators_success = (
	data,
	history,
	deferredPromiseResolvers
) => {
	return (dispatch) => {
		dispatch({
			type: STORE_DEALER_SERVICE_STATIONS_WITH_OPERATORS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const get_linked_station_accounts_success = (
	data,
	history,
	deferredPromiseResolvers
) => {
	return (dispatch) => {
		dispatch({
			type: STORE_LINKED_STATION_ACCOUNTS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const get_linked_station_accounts_error = (data) => {
	return (dispatch) => {
		NotificationManager.error(data);
	};
};

const get_dealer_service_stations_with_operators_error = (data) => {
	return (dispatch) => {
		NotificationManager.error(data);
	};
};

const get_dealer_service_stations_error = (data) => {
	return (dispatch) => {
		NotificationManager.error(data);
	};
};

const username_exists_success = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: STORE_USERNAME_EXISTS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const storeAllServiceStations = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({
			type: STORE_ALL_SERVICE_STATIONS,
			skipLogging: true,
			payload: data,
		});
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const errorFetchingServiceStations = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to get service stations");
	};
};

/**
 * initial user list state
 */
const INIT_USER_LIST_STATE = {
	users: [],
	selectedUser: {},
	roles: [],
	regions: [],
	dealerEnum: 0,
	clerkEnum: 0,
	driverEnum: 0,
	serviceStationLinks: [],
	deleted: null,
	userTotalCount: -1,
	usersPerPage: 10,
	userCurrentPage: 1,
	loading: false,
	serviceStations: [],
	dismissEmailPrompt: false,
	placeholderEmail: "toreplace@email.com",
};

export default (state = INIT_USER_LIST_STATE, action) => {
	switch (action.type) {
		case STORE_UPDATE_LOADING_STATUS:
			return { ...state, loading: action.payload };

		case STORE_ALL_USERS:
			return {
				...state,
				users: action.payload,
				roleToEdit: "",
				userToDelete: null,
			};

		case STORE_ALL_ROLES:
			return {
				...state,
				roles: action.payload,
				driverEnum: action.payload.find((e) => e.role == "Driver").num,
				clerkEnum: action.payload.find((e) => e.role == "Clerk").num,
				dealerEnum: action.payload.find((e) => e.role == "Dealer").num,
			};

		case STORE_ALL_REGIONS:
			return { ...state, regions: action.payload };

		case REMOVE_USER_ENTRY:
			return {
				...state,
				users: state.users.map((user) => {
					if (user.applicationUserId == action.payload.userId)
						user.deleted = true;
					return user;
				}),
				alertDeleted: true,
			};
		
		case RESTORE_USER_ENTRY:
				return {
					...state,
					users: state.users.map((user) => {
						if (user.applicationUserId == action.payload.userId){
							user.deleted = false;
							user.fullName=user.fullName.replace("<deleted>","");
						}
							
						return user;
					}),
					alertRestored: true,
		};

		case SHOW_DELETED:
			return { ...state, showDeleted: !action.payload.toggle };

		case SET_TO_DELETE:
			return { ...state, userToDelete: action.payload.user };

		case SET_TO_RESTORE:
			return { ...state, userToRestore: action.payload.user };

		case STORE_ROLE:
			return { ...state, roleToEdit: action.payload };

		case STORE_DEALER_SERVICE_STATIONS:
			return { ...state, serviceStationLinks: action.payload };

		case STORE_DEALER_SERVICE_STATIONS_WITH_OPERATORS:
			return { ...state, serviceStationsWithOperatorLinks: action.payload };

		case STORE_USERNAME_EXISTS:
			return { ...state, usernameExists: action.payload };

		case STORE_USER:
			return { ...state, selectedUser: action.payload };

		case STORE_CURRENT_USER: //for currently logged in
			return { ...state, currentUser: action.payload };

		case RESET_SELECTED_USER:
			return { ...state, selectedUser: null };

		case STORE_ALL_SERVICE_STATIONS:
			return { ...state, serviceStations: action.payload };

		case STORE_LINKED_STATION_ACCOUNTS:
			return { ...state, linkedStationAccounts: action.payload };

		case DISMISS_EMAIL_PROMPT:
			return { ...state, dismissEmailPrompt: true };

		default:
			return { ...state };
	}
};
