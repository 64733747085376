/**
 * Helpers Functions
 */
import moment from "moment";

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      alpha +
      ")"
    );
  }
  throw new Error("Bad Hex");
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
  let time = timestamp * 1000;
  let formatDate = format ? format : "MM-DD-YYYY";
  return moment(time).format(formatDate);
}

/**
 * Convert Date To Timestamp
 */
export function convertDateToTimeStamp(date, format) {
  let formatDate = format ? format : "YYYY-MM-DD";
  return moment(date, formatDate).unix();
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
  let location = url.pathname;
  let path = location.split("/");
  return path[1];
}

export function equalsIgnoringCase(text, other) {
  return text.localeCompare(other, undefined, { sensitivity: "accent" }) === 0;
}


export function formatCurrency(num)
{
  var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

  return formatter.format(num);
}

export const getDispatchPromise = () => {
	var deferredPromiseResolvers = [];
	var dispatchPromise = new Promise(function (resolve, reject) {
		deferredPromiseResolvers.push({ resolve, reject });
	});
	return { deferredPromiseResolvers, dispatchPromise };
};

export const resolveDispatchPromise = (deferredPromiseResolvers) => {
	if (
		Array.isArray(deferredPromiseResolvers) &&
		deferredPromiseResolvers.length
	)
		deferredPromiseResolvers[0].resolve("success");
};


export const loadUserState = () => {
  try
  {
    const user = localStorage.getItem('user');
    if (user === null)
      return undefined;
    
    return  JSON.parse(user);
  }
  catch(err)
  {
    return undefined;
  }
};


export const saveUserState = (userState) => {
  try
  {
    const { loggedIn, user } = userState;
    const { name, avatar, role} = user;
    const stringUser = JSON.stringify({loggedIn, user: {name, avatar, role}});
    localStorage.setItem('user', stringUser);
  }
  catch(err)
  {
    console.log('error here');
  }
};