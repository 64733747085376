//Action Types
import { NotificationManager } from "react-notifications";
import { getDispatchPromise, resolveDispatchPromise } from "../helpers/helpers";

export const STORE_ACCOUNT = "STORE_ACCOUNT";
export const API_GET_ACCOUNT_BY_SERVICE_STATION_ID =
	"API_GET_ACCOUNT_BY_SERVICE_STATION_ID";
export const API_GET_ACCOUNTS_AVAILABLE_TO_USER =
	"API_GET_ACCOUNTS_AVAILABLE_TO_USER";

export const STORE_UPDATE_LOADING_STATUS = "STORE_UPDATE_LOADING_STATUS";

//Actions Creators
export const createGetAccountsAvailableToUser = (userId) => {
	return async (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_GET_ACCOUNTS_AVAILABLE_TO_USER,
			payload: {
				url: `/accounts/GetAccountsAvailableToUser?id=${userId}`,
				method: "get",
				onSuccess: storeAccount,
				onError: errorFetchingAccount,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

const errorFetchingAccount = (message) => {
	return (dispatch) => {
		dispatch({ type: HANDLE_ERROR, payload: { message: message } });
		NotificationManager.error("Unable to get account");
	};
};

const storeAccount = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		dispatch({ type: STORE_ACCOUNT, skipLogging: true, payload: data });
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

/**
 * initial account state
 */
const INIT_ACCOUNT_STATE = {
  accounts: null,
  loading: false,
};

export default (state = INIT_ACCOUNT_STATE, action) => {
  switch (action.type) {
    case STORE_ACCOUNT:
      return { ...state, accounts: action.payload};

    default:
      return { ...state };
  }
};
