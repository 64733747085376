//Action Types
import { NotificationManager } from "react-notifications";

export const API_GET_ALL_SETTINGS = "API_GET_ALL_SETTINGS";
export const STORE_ALL_SETTINGS = "STORE_ALL_SETTINGS";

export const  API_SAVE_ALL_SETTINGS  = "API_SAVE_ALL_SETTINGS";
export const  STORE_UPDATED_SETTINGS = "STORE_UPDATED_SETTINGS";

export const API_GET_DISTRIBUTION_CENTRES = "API_GET_DISTRIBUTION_CENTRES";
export const STORE_DISTRIBUTION_CENTRES = "STORE_DISTRIBUTION_CENTRES";

export const API_GET_DASHBOARD_STATS = "API_GET_DASHBOARD_STATS";
export const STORE_DASHBOARD_STATS = "STORE_DASHBOARD_STATS";

//Actions Creators





export const createSetApplicationSettingsAction = (settings) => {
  return (dispatch) => {
    dispatch({
      type: API_SAVE_ALL_SETTINGS,
      payload: {
        url: "/ApplicationSettings/setApplicationSettings",
        method: "post",
        data: settings,
        onSuccess: successSavingSettings,
        onError: errorSavingSettings,
      },
    });
  };
};

const successSavingSettings = (data) => {
  return (dispatch) => {
    dispatch({ type: STORE_ALL_SETTINGS, skipLogging: true, payload: data });
    NotificationManager.success("Successfully saved settings");
  };
};

const errorSavingSettings = (message) => {
  return (dispatch) => {
    NotificationManager.error("Unable to save settings");
  };
};

export const createGetApplicationSettingsAction = () => {
  return (dispatch) => {
    dispatch({
      type: API_GET_ALL_SETTINGS,
      payload: {
        url: "/ApplicationSettings/getApplicationSettings",
        method: "get",
        onSuccess: storeAllSettings,
        onError: errorFetchingSettings,
      },
    });
  };
};

const storeAllSettings = (data) => {
  return (dispatch) => {
    dispatch({ type: STORE_ALL_SETTINGS, skipLogging: true, payload: data });
  };
};

const errorFetchingSettings = (message) => {
  return (dispatch) => {
    NotificationManager.error("Unable to get settings");
  };
};




export const createGetDistributionCentreAction = () => {
  return (dispatch) => {
    dispatch({
      type: API_GET_DISTRIBUTION_CENTRES,
      payload: {
        url: "/ApplicationSettings/getDistributionCentres",
        method: "get",
        onSuccess: storeDistributionCentres,
        onError: errorFetchingDistributionCentres,
      },
    });
  };
};

const storeDistributionCentres = (data) => {
  return (dispatch) => {
    dispatch({ type: STORE_DISTRIBUTION_CENTRES, skipLogging: true, payload: data });
  };
};

const errorFetchingDistributionCentres = (message) => {
  return (dispatch) => {

  };
};

export const createGetAllDashboardStats = () => {
  return (dispatch) => {
    dispatch({
      type: API_GET_DASHBOARD_STATS,
      payload: {
        url: "/ApplicationSettings/getAllDashboardStats",
        method: "get",
        onSuccess: storeDashboardStats,
        onError: errorFetchingDashboardStats,
      },
    });
  };
};

const storeDashboardStats = (data) => {
  return (dispatch) => {
    dispatch({ type: STORE_DASHBOARD_STATS, skipLogging: true, payload: data });
  };
};

const errorFetchingDashboardStats = (message) => {
  return (dispatch) => {

  };
};

/**
 * initial Application Settings state
 */
const INIT_SETTINGS_STATE = {
  loading: false,
  distributionCentres: null
};

export default (state = INIT_SETTINGS_STATE, action) => {
  switch (action.type) {
    case STORE_ALL_SETTINGS:
      return { ...state, vat: action.payload.vat, activeOrderDuration: action.payload.activeOrderDuration, loading: false };

    case STORE_DISTRIBUTION_CENTRES:
      return { ...state, distributionCentres: action.payload, loading: false };

    case STORE_DASHBOARD_STATS:
      return { 
        ...state, 
        dashboardStats: action.payload.dashboardStats,
        dailyVisitorsForCurrentMonths: action.payload.dailyVisitorsForCurrentMonths,
        orderCountByDayForCurrentMonth: action.payload.orderCountByDayForCurrentMonths,
        orderRevenueByDayForCurrentMonth: action.payload.orderRevenueByDayForCurrentMonth,
        orderStatusCountForCurrentDay: action.payload.orderStatusCountForCurrentDay,
      };
        
    default:
      return { ...state, loading: false };
  }
};