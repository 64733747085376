import { TextsmsTwoTone } from "@material-ui/icons";
import moment from "moment";
import { getDispatchPromise, resolveDispatchPromise } from "../helpers/helpers";
//Action Types
import {
  NotificationManager,
  HANDLE_ERROR
} from "react-notifications";

export const API_GET_ALL_ROUTES = "API_GET_ALL_ROUTES";
export const API_GET_ALL_ROUTE_PLANS = "API_GET_ALL_ROUTE_PLANS";
export const API_GET_ALL_ROUTE_DETAILS = "API_GET_ALL_ROUTE_DETAILS";

export const API_GET_ROUTE_BY_ID = "API_GET_ROUTE_BY_ID";
export const API_GET_ROUTE_PLAN_BY_ID = "API_GET_ROUTE_PLAN_BY_ID";
export const API_GET_ROUTE_DETAILS_BY_ID = "API_GET_ROUTE_DETAILS_BY_ID";


export const API_GET_ROUTES_BY_ROUTE_PLAN_ID = "API_GET_ROUTES_BY_ROUTE_PLAN_ID";
export const API_GET_ROUTE_DETAILS_BY_ROUTE_PLAN_ID = "API_GET_ROUTE_DETAILS_BY_ROUTE_PLAN_ID";

export const API_DOWNLOAD_DAILY_SCHEDULE = "API_DOWNLOAD_DAILY_SCHEDULE";

export const STORE_ROUTES = "STORE_API_GET_ALL_ROUTES";
export const STORE_ROUTE_PLANS = "STORE_API_GET_ALL_ROUTE_PLANS";
export const STORE_ROUTE_DETAILS = "STORE_API_GET_ALL_ROUTE_DETAILS";

export const STORE_ROUTE_BY_ID = "STORE_API_GET_ROUTE_BY_ID";
export const STORE_ROUTE_PLAN_BY_ID = "STORE_API_GET_ROUTE_PLAN_BY_ID";
export const STORE_ROUTE_DETAILS_BY_ID = "STORE_API_GET_ROUTE_DETAILS_BY_ID";

export const STORE_ROUTES_BY_ROUTE_PLAN_ID = "STORE_ROUTES_BY_ROUTE_PLAN_ID";
export const STORE_ROUTE_DETAILS_BY_ROUTE_PLAN_ID =
	"STORE_ROUTE_DETAILS_BY_ROUTE_PLAN_ID";

export const API_RUN_SCHEDULER = "API_RUN_SCHEDULER";
export const API_CHECK_SCHEDULER = "API_CHECK_SCHEDULER";
export const STORE_SCHEDULE_RUNNING_STATE = "STORE_SCHEDULE_RUNNING_STATE";
export const STORE_SCHEDULE_NOT_RUNNING_STATE =
	"STORE_SCHEDULE_NOT_RUNNING_STATE";

export const API_GET_DAILY_SCHEDULE = "API_GET_DAILY_SCHEDULE";
export const STORE_DAILY_SCHEDULE = "STORE_DAILY_SCHEDULE";
export const UPDATE_DAILY_SCHEDULE = "UPDATE_DAILY_SCHEDULE";

export const UPDATE_SELECTED_DISTRIBUTION_CENTRE =
	"UPDATE_SELECTED_DISTRIBUTION_CENTRE";
export const UPDATE_SELECTED_DELIVERY_DATE = "UPDATE_SELECTED_DELIVERY_DATE";

export const API_CHECK_DAILY_SCHEDULE_EXISTS =
	"API_CHECK_DAILY_SCHEDULE_EXISTS";
export const STORE_DAILY_SCHEDULE_EXISTING_STATE =
	"STORE_DAILY_SCHEDULE_EXISTING_STATE";

export const API_SAVE_DAILY_SCHEDULE = "API_SAVE_DAILY_SCHEDULE";

export const UPDATE_LOCKED_ROUTES = "UPDATE_LOCKED_ROUTES";

export const API_GET_DAILY_SCHEDULE_ORDERS = "API_GET_DAILY_SCHEDULE_ORDERS";
export const STORE_DAILY_SCHEDULE_ORDERS = "STORE_DAILY_SCHEDULE_ORDERS";


export const API_GET_DAILY_PROVISIONAL_SCHEDULE =
	"API_GET_DAILY_PROVISIONAL_SCHEDULE";
export const STORE_DAILY_PROVISIONAL_SCHEDULE =
	"STORE_DAILY_PROVISIONAL_SCHEDULE";

export const API_UPDATE_ORDER_ITEM_RECOMMENDATIONS =
	"API_UPDATE_ORDER_ITEM_RECOMMENDATIONS";

export const UPDATE_PROVISIONAL_QUANTITIES = "UPDATE_PROVISIONAL_QUANTITIES";

export const CLEAR_ORDER_ITEM_UPDATED_QUANTITIES =
	"CLEAR_ORDER_ITEM_UPDATED_QUANTITIES";

export const FILL_IN_ALL_ITEM_UPDATED_QUANTITIES =
	"FILL_IN_ALL_ITEM_UPDATED_QUANTITIES";

export const STORE_AUTO_ADJUST_THRESHOLD = "STORE_AUTO_ADJUST_THRESHOLD";

export const API_GET_DAILY_CONFIRMED_ORDERS = "API_GET_DAILY_CONFIRMED_ORDERS";
export const STORE_DAILY_CONFIRMED_ORDERS = "STORE_DAILY_CONFIRMED_ORDERS";

export const API_CONFIRMED_SCHEDULED_ORDERS = "API_CONFIRMED_SCHEDULED_ORDERS";

//Actions Creators
export const createRunSchedulerAction = (schedulerInput) => {
	return (dispatch) => {
		if (
			!schedulerInput ||
			!schedulerInput.deliveryDate ||
			!schedulerInput.distributionCentre
		) {
			dispatch({
				type: API_RUN_SCHEDULER,
				payload: {
					url: `/scheduling/run`,
					method: "get",
					//data: id,
					onSuccess: storeSchedulerRunningState,
					onError: errorGettingSchedulerRunningState,
				},
			});
		} else {
			dispatch({
				type: API_RUN_SCHEDULER,
				payload: {
					url: `/scheduling/runschedulerfor`,
					method: "post",
					data: schedulerInput,
					onSuccess: storeSchedulerRunningState,
					onError: errorGettingSchedulerRunningState,
				},
			});
		}
	};
};

const storeSchedulerRunningState = (data, history) => {
	return (dispatch) => {
		dispatch({
			type: STORE_SCHEDULE_RUNNING_STATE,
			skipLogging: true,
			payload: data,
		});
	};
};

const errorGettingSchedulerRunningState = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to run the scheduler");
	};
};

//Actions Creators
export const createCheckSchedulerAction = (jobId) => {
	return (dispatch) => {
		dispatch({
			type: API_CHECK_SCHEDULER,
			payload: {
				url: `/scheduling/checkrunning?jobId=${jobId}`,
				method: "get",
				//data: id,
				onSuccess: storeSchedulerStyleRunningState,
				onError: () => {},
			},
		});
	};
};

const storeSchedulerStyleRunningState = (stoppedRunning, history) => {
	return (dispatch) => {
		if (stoppedRunning) {
			dispatch({
				type: STORE_SCHEDULE_NOT_RUNNING_STATE,
				skipLogging: true,
				payload: stoppedRunning,
			});
		}
	};
};

//Actions Creators
export const createCheckDailyScheduleExistsAction = (schedulerInput) => {
	return (dispatch) => {
		dispatch({
			type: API_CHECK_DAILY_SCHEDULE_EXISTS,
			payload: {
				url: "/scheduling/DailyScheduleExists",
				method: "post",
				data: schedulerInput,
				onSuccess: storeDailyScheduleExistsState,
				onError: () => {},
			},
		});
	};
};

const storeDailyScheduleExistsState = (scheduleExists) => {
	return (dispatch) => {
		return (dispatch) => {
			dispatch({
				type: STORE_DAILY_SCHEDULE_EXISTING_STATE,
				skipLogging: true,
				payload: scheduleExists,
			});
		};
	};
};

export const getRoutesByRoutePlanId = (searchValues) => {
	return async (dispatch) => {
		dispatch({
			type: API_GET_ALL_ROUTES,
			payload: {
				url: `/Scheduling/GetRoutesByRoutePlanId`,
				method: "post",
				data: searchValues,
				onSuccess: (data) => {
					return (dispatch) => {
						dispatch({
							type: STORE_ROUTES_BY_ROUTE_PLAN_ID,
							skipLogging: true,
							payload: data.map((vehicle) => ({
								id: vehicle.id,
								name: vehicle.licensePlate,
								contractor: vehicle.contractor
									? `${vehicle.contractor.firstName} ${vehicle.contractor.lastName}`
									: "Unknown",
							})),
						});
					};
				},
				onError: errorFetchingData,
			},
		});
	};
};

export const getRoutedDetailsByRoutePlanId = (searchValues) => {
	return async (dispatch) => {
		dispatch({
			type: API_GET_ALL_ROUTES,
			payload: {
				url: `/Scheduling/GetRouteDetailsByRoutePlanId`,
				method: "post",
				data: searchValues,
				onSuccess: (data) => {
					return (dispatch) => {
						let count = 0;
						const events = data.map((routeDetails) => {
							if (routeDetails.type == "checkpoint")
								return {
									resourceId: routeDetails.vehicleId,
									id: ++count,
									name: `${routeDetails.serviceStation}`, //`${routeDetails.fromLocation.locationName ?? ""} - ${routeDetails.toLocation.locationName ?? ""}`,
									startDate: new Date(routeDetails.startDate),
									endDate: new Date(routeDetails.startDate),
									description: routeDetails.description,
									location: routeDetails.serviceStationLocation,
									eventColor: "green",
									contractor: routeDetails.contractor,
								};
							else
								return {
									resourceId: routeDetails.vehicleId,
									id: ++count,
									name: `${routeDetails.serviceStation}`, //`${routeDetails.fromLocation.locationName ?? ""} - ${routeDetails.toLocation.locationName ?? ""}`,
									startDate: new Date(routeDetails.startDate),
									endDate: new Date(routeDetails.endDate),
									description: routeDetails.description,
									location: routeDetails.serviceStationLocation,
									eventColor: routeDetails.type == "drop" ? "blue" : "gray",
									iconCls: "fas fa-truck",
									eventType: "Meeting",
									contractor: routeDetails.contractor,
								};
						});
						dispatch({
							type: STORE_ROUTE_DETAILS_BY_ROUTE_PLAN_ID,
							skipLogging: true,
							payload: {
								events: events,
								dependencies: getDependencies(events),
							},
						});
					};
				},
				onError: errorFetchingData,
			},
		});
	};
};

function getDependencies(events) {
	let count = 0;
	const dependencies = [];
	if (events && events.length > 1) {
		let currentResourceId = events[0].resourceId;
		let currentEventId = events[0].id;
		for (var i = 1; i < events.length - 1; i++) {
			let nextResourceId = events[i].resourceId;
			let nextEventId = events[i].id;
			if (currentResourceId != nextResourceId) {
				currentResourceId = nextResourceId;
				continue;
			}

			dependencies.push({
				id: ++count,
				from: currentEventId,
				to: nextEventId,
			});

			currentEventId = events[i].id;
		}

		return dependencies;
	} else return [];

	/* dependencies.push({
         id   : ++count,
         from : 1,
         to   : 2
     });

     return dependencies;*/
}

export const getAllRoutes = () => {
	return async (dispatch) => {
		dispatch({
			type: API_GET_ALL_ROUTES,
			payload: {
				url: `/Scheduling/GetAllRoutes`,
				method: "get",
				onSuccess: storeGetAllRoutesRunningState,
				onError: errorFetchingData,
			},
		});
	};
};

const storeGetAllRoutesRunningState = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_ROUTES,
			skipLogging: true,
			payload: data,
		});
	};
};

export const getRouteById = (Id) => {
	return async (dispatch) => {
		dispatch({
			type: API_GET_ROUTE_BY_ID,
			payload: {
				url: `/Scheduling/GetRouteById?id=${Id}`,
				method: "get",
				onSuccess: (data) => {
					return (dispatch) => {
						dispatch({
							type: STORE_ROUTE_BY_ID,
							payload: data,
						});
					};
				},
				onError: errorFetchingData,
			},
		});
	};
};

const errorFetchingData = (message) => {
	return (dispatch) => {
		//NotificationManager.error("Unable to get data!");
	};
};

export const createGetDailyScheduleAction = (values) => {
	return (dispatch) => {
		dispatch({
			type: API_GET_DAILY_SCHEDULE,
			payload: {
				url: `/scheduling/GetDailySchedule`,
				method: "post",
				data: values,
				onSuccess: storeDailyScheduleState,
				onError: errorGettingDailyScheduleState,
			},
		});
	};
};

const storeDailyScheduleState = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_DAILY_SCHEDULE,
			skipLogging: true,
			payload: flattenDailySchedule(data),
		});

		dispatch({
			type: STORE_AUTO_ADJUST_THRESHOLD,
			skipLogging: true,
			payload: data.autoAdjustThreshold,
		});
	};
};

const errorGettingDailyScheduleState = (message) => {
	return (dispatch) => {
		//NotificationManager.error("Unable to get data!");
	};
};

export const createUpdateDailyScheduleAction = (vehicleId, routeId, locked) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_DAILY_SCHEDULE,
			payload: { vehicleId, routeId, locked },
		});
	};
};

export const createDownloadDailyScheduleAction = (values) => {
	return (dispatch) => {
		dispatch({
			type: API_DOWNLOAD_DAILY_SCHEDULE,
			skipLogging: true,
			payload: {
				url: `/scheduling/downloadschedule`,
				method: "post",
				data: values,
			},
		});
	};
};

export const createGetDailyProvisionalScheduleAction = (values) => {
	return (dispatch) => {
		dispatch({
			type: API_GET_DAILY_PROVISIONAL_SCHEDULE,
			payload: {
				url: `/scheduling/GetDailyProvisionalSchedule`,
				method: "post",
				data: values,
				onSuccess: storeDailyProvisionalSchedule,
				onError: errorGettingDailyProvisionalSchedule,
			},
		});
	};
};

const storeDailyProvisionalSchedule = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_DAILY_PROVISIONAL_SCHEDULE,
			skipLogging: true,
			payload: data,
		});
	};
};

const errorGettingDailyProvisionalSchedule = (message) => {
	return (dispatch) => {
		//NotificationManager.error("Unable to get data!");
	};
};

export const createUpdateOrderItemsWithRecommendationAction = (values) => {
	return (dispatch) => {
		dispatch({
			type: API_UPDATE_ORDER_ITEM_RECOMMENDATIONS,
			payload: {
				url: `/scheduling/UpdateOrderItemRecommendations`,
				method: "post",
				data: { orderItems: values },
				onSuccess: storeUpdatedOrderItemRecommendations,
				onError: errorUpdatingOrderItemRecommendations,
			},
		});
	};
};

const storeUpdatedOrderItemRecommendations = (data) => {
	return (dispatch) => {
		NotificationManager.success("Updated order quantity!");
	};
};

const errorUpdatingOrderItemRecommendations = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to update order!");
	};
};

export const createGetDailyScheduleOrdersAction = (values) => {
	return (dispatch) => {
		dispatch({
			type: API_GET_DAILY_SCHEDULE_ORDERS,
			payload: {
				url: `/scheduling/GetDailyScheduleOrders`,
				method: "post",
				data: values,
				onSuccess: storeDailyScheduleOrdersState,
				onError: errorGettingDailyScheduleOrdersState,
			},
		});
	};
};

const storeDailyScheduleOrdersState = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_DAILY_SCHEDULE_ORDERS,
			skipLogging: true,
			payload: flattenDailyScheduleOrders(data),
		});
	};
};

const errorGettingDailyScheduleOrdersState = (message) => {
	return (dispatch) => {
		//NotificationManager.error("Unable to get data!");
	};
};

export const createSaveDailyScheduleAction = (dailySchedule) => {
	return (dispatch) => {
		dispatch({
			type: API_SAVE_DAILY_SCHEDULE,
			payload: {
				url: `/scheduling/SaveDailySchedule`,
				method: "post",
				data: dailySchedule,
				onSuccess: () => {
					NotificationManager.success("Routes updated successfully!");
					createGetDailyScheduleAction(dailySchedule);
				},
				onError: () => {},
			},
		});
	};
};

/*-------------------------
 *
 * This will replace an search for schedule or setting properties
 *--------------------------*/
export const createSearchDailyScheduleAction = (values) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SELECTED_DISTRIBUTION_CENTRE,
			payload: values.deliveryDate,
		});

		dispatch({
			type: UPDATE_SELECTED_DELIVERY_DATE,
			payload: values.distributionCentre,
		});

		dispatch({
			type: API_GET_DAILY_SCHEDULE,
			payload: {
				url: `/scheduling/GetDailySchedule`,
				method: "post",
				data: values,
				onSuccess: storeDailyScheduleState,
				onError: errorGettingDailyScheduleState,
			},
		});
	};
};
//--------------------------

export const createUpdateDailyScheduleDistributionCentreAction = (
	newDistributionCentreId
) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SELECTED_DISTRIBUTION_CENTRE,
			payload: newDistributionCentreId,
		});
	};
};

export const createUpdateDailyScheduleDeliveryDateAction = (
	newDeliveryDate
) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SELECTED_DELIVERY_DATE,
			payload: newDeliveryDate,
		});
	};
};

export const createUpdateLockedRouteAction = (colName, index) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_LOCKED_ROUTES,
			payload: { colName, index },
		});
	};
};

const flattenDailySchedule = (dailySchedule) => {
	const localData = [];
	let id = 0;

	if (
		dailySchedule.vehicleSchedule &&
		dailySchedule.vehicleSchedule.length > 0
	) {
		dailySchedule.vehicleSchedule.forEach((vs) => {
			const temp = {
				rowId: ++id,
				driver: vs.driver.name,
				vehicle: vs.vehicle.name,
				vehicleOwner: vs.vehicle.contractor
					? `${vs.vehicle.contractor.firstName} ${vs.vehicle.contractor.lastName}`
					: "",
				tripOne: vs.tripOne ? vs.tripOne.serviceStationDeliveries : [],
				tripTwo: vs.tripTwo ? vs.tripTwo.serviceStationDeliveries : [],
				tripThree: vs.tripThree ? vs.tripThree.serviceStationDeliveries : [],
				tripOneRouteId: vs.tripOne ? vs.tripOne.routeId : 0,
				tripTwoRouteId: vs.tripTwo ? vs.tripTwo.routeId : 0,
				tripThreeRouteId: vs.tripThree ? vs.tripThree.routeId : 0,
				tripOneLocked: vs.tripOne ? vs.tripOne.locked : false,
				tripTwoLocked: vs.tripTwo ? vs.tripTwo.locked : false,
				tripThreeLocked: vs.tripThree ? vs.tripThree.locked : false,
				routePlan: dailySchedule.routePlan,
				provisional: dailySchedule.provisional,
				vehicleCompartments:
					vs.vehicle.vehicleType &&
					vs.vehicle.vehicleType.vehicleTypeCompartments &&
					vs.vehicle.vehicleType.vehicleTypeCompartments.length > 0
						? vs.vehicle.vehicleType.vehicleTypeCompartments
						: [],
				vehicleCompartmentsTotal: getTotals(vs),
			};
			localData.push(temp);
		});
	}
	return localData;
};

const getTotals = (vs) => {
	let sum = 0;

	if (
		vs.vehicle.vehicleType &&
		vs.vehicle.vehicleType.vehicleTypeCompartments &&
		vs.vehicle.vehicleType.vehicleTypeCompartments.length > 0
	) {
		vs.vehicle.vehicleType.vehicleTypeCompartments.forEach((x) => {
			if (x != null) {
				sum += x.vehicleCompartment ? x.vehicleCompartment.size : 0;
			}
		});
	}

	return sum;
};

const flattenDailyScheduleOrders = (dailyScheduleOrders) => {
	const localData = [];

	if (dailyScheduleOrders.length > 0) {
		dailyScheduleOrders.forEach((o) => {
			const temp = {
				serviceStation: o.serviceStationAccount.name,
				orderItems: o.orderItems,
				orderStatus: o.orderStatus.name,
				deliveryDate: o.deliverOn,
			};
			localData.push(temp);
		});
	}
	return localData;
};

export const updateProvisionalQuantitiesAction = (position, data) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_PROVISIONAL_QUANTITIES,
			payload: { position, data },
		});
	};
};

export const createFillInAllProvisionalQuantitiesAction = () => {
	return (dispatch) => {
		dispatch({
			type: FILL_IN_ALL_ITEM_UPDATED_QUANTITIES,
			payload: {},
		});
	};
};

export const createClearOrderItemsWithRecommendationAction = () => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_ORDER_ITEM_UPDATED_QUANTITIES,
			payload: {},
		});
	};
};



export const createConfirmOrdersAction = (values) => {
	return (dispatch) => {
		var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
		dispatch({
			type: API_CONFIRMED_SCHEDULED_ORDERS,
			payload: {
				url: '/scheduling/ConfirmedScheduledOrders',
				method: "post",
				data: values,
				onSuccess: successConfirmingOrdersState,
				onError: errorConfirmingOrdersState,
				deferredPromiseResolvers,
			},
		});
		return dispatchPromise;
	};
};

//use this to set a flag to 
const successConfirmingOrdersState = (data, history, deferredPromiseResolvers) => {
	return (dispatch) => {
		//return a resolved promised
		resolveDispatchPromise(deferredPromiseResolvers);
	};
};

const errorConfirmingOrdersState = (message) => {
	return (dispatch) => {};

};




export const createGetDailyConfirmedOrdersAction = (values) => {
	return (dispatch) => {
		dispatch({
			type: API_GET_DAILY_CONFIRMED_ORDERS,
			payload: {
				url: `/scheduling/GetDailyConfirmedOrders`,
				method: "post",
				data: values,
				onSuccess: storeDailyConfirmedOrdersState,
				onError: errorGettingDailyConfirmedOrdersState,
			},
		});
	};
};



const storeDailyConfirmedOrdersState = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_DAILY_CONFIRMED_ORDERS,
			skipLogging: true,
			payload: flattenDailyScheduleOrders(data),
		});
	};
};

const errorGettingDailyConfirmedOrdersState = (message) => {
	return (dispatch) => {};
};



const INIT_SCHEDULE_STATE = {
	schedulerRunning: false,
	schedulerJobId: "",
	allRoutes: [],
	allRoutePlans: [],
	allRouteDetails: [],
	routesByRoutePlanId: [],
	routeDetailsByRoutePlanId: [],
	route: null,
	routePlan: null,
	routeDetails: null,
	dailySchedule: null,
	dailyScheduleExists: false,
	dailyScheduleSelectedDistributionCentre: 1,
	dailyScheduleSelectedDeliveryDate: null,
	loading: false,
	updatedProvisionalQuantities: [],
	autoAdjustThreshold: 0,
};

export default (state = INIT_SCHEDULE_STATE, action) => {
	switch (action.type) {
		case STORE_SCHEDULE_RUNNING_STATE:
			return {
				...state,
				schedulerRunning: true,
				schedulerJobId: action.payload,
			};

		case STORE_SCHEDULE_NOT_RUNNING_STATE:
			return {
				...state,
				schedulerRunning: false,
			};

		case STORE_ROUTES:
			return {
				...state,
				allRoutes: action.payload,
			};

		case STORE_ROUTE_DETAILS:
			return {
				...state,
				allRouteDetails: action.payload,
				loading: false,
			};

		case STORE_ROUTE_PLANS:
			return {
				...state,
				allRoutePlans: action.payload,
				loading: false,
			};

		case STORE_ROUTE_BY_ID:
			return {
				...state,
				route: action.payload,
				loading: false,
			};

		case STORE_ROUTE_DETAILS_BY_ID:
			return {
				...state,
				routeDetails: action.payload,
				loading: false,
			};

		case STORE_ROUTE_PLAN_BY_ID:
			return {
				...state,
				routePlan: action.payload,
				loading: false,
			};

		case STORE_ROUTES_BY_ROUTE_PLAN_ID:
			return {
				...state,
				routesByRoutePlanId: action.payload,
				loading: false,
			};

		case STORE_ROUTE_DETAILS_BY_ROUTE_PLAN_ID:
			return {
				...state,
				routeDetailsByRoutePlanId: action.payload.events,
				routeDependencies: action.payload.dependencies,
				loading: false,
			};

		case STORE_DAILY_SCHEDULE:
			return {
				...state,
				dailySchedule: action.payload,
				loading: false,
			};

		case STORE_DAILY_SCHEDULE_ORDERS:
			return {
				...state,
				dailyScheduleOrders: action.payload,
				loading: false,
			};


		case STORE_DAILY_CONFIRMED_ORDERS:
				return {
				...state,
				dailyConfirmedOrders: action.payload,
				loading: false,
			};

		case UPDATE_LOCKED_ROUTES:
			if (action.payload.colName === "one") {
				return {
					...state,
					dailySchedule: state.dailySchedule.map((el) =>
						el.rowId === action.payload.index + 1
							? { ...el, tripOneLocked: !el.tripOneLocked }
							: el
					),
					loading: false,
				};
			} else if (action.payload.colName === "two") {
				return {
					...state,
					dailySchedule: state.dailySchedule.map((el) =>
						el.rowId === action.payload.index + 1
							? { ...el, tripTwoLocked: !el.tripTwoLocked }
							: el
					),
					loading: false,
				};
			} else {
				return {
					...state,
					dailySchedule: state.dailySchedule.map((el) =>
						el.rowId === action.payload.index + 1
							? { ...el, tripThreeLocked: !el.tripThreeLocked }
							: el
					),
					loading: false,
				};
			}

		case UPDATE_SELECTED_DISTRIBUTION_CENTRE:
			return {
				...state,
				dailyScheduleSelectedDistributionCentre: action.payload,
			};

		case UPDATE_SELECTED_DELIVERY_DATE:
			return {
				...state,
				dailyScheduleSelectedDeliveryDate: action.payload,
			};

		case STORE_DAILY_SCHEDULE_EXISTING_STATE:
			return {
				...state,
				dailyScheduleExists: action.payload,
			};

		case STORE_DAILY_PROVISIONAL_SCHEDULE:
			return {
				...state,
				dailyProvisionalSchedule: action.payload,
				updatedProvisionalQuantities: Array.from({
					length: action.payload.length,
				}),
				loading: false,
			};

		case UPDATE_PROVISIONAL_QUANTITIES:
			return {
				...state,
				updatedProvisionalQuantities: state.updatedProvisionalQuantities.map(
					(x, i) => {
						return i == action.payload.position ? action.payload.data : x;
					}
				),
			};

		case CLEAR_ORDER_ITEM_UPDATED_QUANTITIES:
			return {
				...state,
				updatedProvisionalQuantities: Array.from({
					length: state.updatedProvisionalQuantities.length,
				}),
			};

		case FILL_IN_ALL_ITEM_UPDATED_QUANTITIES:
			return {
				...state,
				updatedProvisionalQuantities: state.updatedProvisionalQuantities.map(
					(x, i) => {
						return {
							orderItemId: state.dailyProvisionalSchedule[i].orderItemId,
							quantity: state.dailyProvisionalSchedule[i].recommendedChange,
						};
					}
				),
			};

		case STORE_AUTO_ADJUST_THRESHOLD:
			return {
				...state,
				autoAdjustThreshold: action.payload,
			};

		default:
			return {
				...state,
				loading: false,
			};
	}
};