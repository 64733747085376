/**
 * Users Routes
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PrivateRoute from "../../container/PrivateRoute"
// async components

import {
    AsyncUsersListComponent, AsyncEditUserComponent, AsyncCreateUserComponent, AsyncUserProfileComponent, AsyncSubmitEmailComponent
} from 'Components/AsyncComponent/AsyncComponent';
 

const Users = ({ match }) => (
    <div className="content-wrapper">
        <Helmet>
            <title>Users</title>
            <meta name="description" content="User List" />
        </Helmet>

        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/user-list`} />
            <Route path={`${match.url}/user-list`} render={(props) => <PrivateRoute {...props} claims={["Admin"]} routecomponent={AsyncUsersListComponent} />} />
            <Route path={`${match.url}/create-user`} render={(props) => <PrivateRoute {...props} claims={["Admin"]} routecomponent={AsyncCreateUserComponent} />} />
            <Route path={`${match.url}/submit-email`} render={(props) => <PrivateRoute {...props} claims={["Admin"]} routecomponent={AsyncSubmitEmailComponent} />} />
            <Route path={`${match.url}/user-profile/:toEdit`}   render={(props) => <PrivateRoute {...props}  routecomponent={AsyncUserProfileComponent } />} />
            <Route path={`${match.url}/:toEdit`}   render={(props) => <PrivateRoute {...props}   routecomponent={AsyncEditUserComponent } />} />
            
        </Switch>
    </div>
);


export default Users;
