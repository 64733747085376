import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { createGetApplicationSettingsAction } from "../store/setting";

class ApplicationSettings extends Component {
  state = {
    delay: 3000,
  };

  componentDidMount() {
    this.interval = setInterval(
      this.getApplicationSettings(),
      this.state.delay
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.delay !== this.state.delay) {
      clearInterval(this.interval);
      this.interval = setInterval(
        this.getApplicationSettings(),
        this.state.delay
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getApplicationSettings = () => {
    if (this.props.user && this.props.loggedIn)
      this.props.createGetApplicationSettingsAction();
  };

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  let user = {};
  let loggedIn = false;
  if (state.authentication) {
    user = state.authentication.user;
    loggedIn = state.authentication.loggedIn;
  }

  return { user, loggedIn };
}

export default connect(mapStateToProps, { createGetApplicationSettingsAction })(
  ApplicationSettings
);
