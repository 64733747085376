import { getDispatchPromise, resolveDispatchPromise } from "../helpers/helpers";

import { NotificationManager } from "react-notifications";

//Action Types
export const API_GET_ALL_ORDER_STATUSES = "API_GET_ALL_ORDER_STATUSES";
export const API_GET_ALL_SERVICE_STATIONS = "API_GET_ALL_SERVICE_STATIONS";
export const API_GET_DELETED_ORDERS = "API_GET_DELETED_ORDERS";
export const API_GET_SERVICE_STATIONS_AVAILABLE_TO_USER =
	"API_GET_SERVICE_STATIONS_AVAILABLE_TO_USER";
export const API_GET_AVAILABLE_BALANCE = "API_GET_AVAILABLE_BALANCE";
export const API_GET_ALL_PRODUCTS = "API_GET_ALL_PRODUCTS";
export const STORE_ALL_ORDER_STATUSES = "STORE_ALL_ORDER_STATUSES";
export const STORE_ALL_SERVICE_STATIONS = "STORE_ALL_SERVICE_STATIONS";
export const STORE_DELETED_ORDERS = "STORE_DELETED_ORDERS";
export const STORE_SERVICE_STATIONS = "STORE_SERVICE_STATIONS";
export const STORE_ALL_PRODUCTS = "STORE_ALL_PRODUCTS";
export const API_GET_ALL_ORDERS = "API_GET_ALL_ORDERS";
export const API_GET_OPEN_ORDERS = "API_GET_OPEN_ORDERS";
export const API_GET_REJECTED_ORDERS = "API_GET_REJECTED_ORDERS";
export const API_GET_SCHEDULED_ORDERS = "API_GET_SCHEDULED_ORDERS";
export const API_GET_CONFIRMED_ORDERS = "API_GET_CONFIRMED_ORDERS";
export const API_GET_AMENDED_ORDERS = "API_GET_AMENDED_ORDERS";
export const API_GET_PRE_LOADED_ORDERS = "API_GET_PRE_LOADED_ORDERS";
export const API_GET_LOADED_ORDERS = "API_GET_LOADED_ORDERS";
export const API_GET_CLOSED_ORDERS = "API_GET_CLOSED_ORDERS";
export const API_GET_CANCELLED_ORDERS = "API_GET_CANCELLED_ORDERS";
export const API_GET_DEALERS_BY_ACCOUNT = "API_GET_DEALERS_BY_ACCOUNT";
export const API_CREATE_ORDER = "API_CREATE_ORDER";
export const API_EDIT_ORDER = "API_EDIT_ORDER";
export const API_CANCEL_ORDER = "API_CANCEL_ORDER";
export const API_DELETE_ORDER = "API_DELETE_ORDER";
export const API_RESTORE_ORDER = "API_RESTORE_ORDER";
export const API_GET_ORDER = "API_GET_ORDER";
export const API_GET_RECENT_ORDERS = "API_GET_RECENT_ORDERS";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const REMOVE_DELETED_ORDER = "REMOVE_DELETED_ORDER";
export const STORE_ALL_ORDERS = "STORE_ALL_ORDERS";
export const STORE_ORDER = "STORE_ORDER";
export const STORE_RECENT_ORDERS = "STORE_RECENT_ORDERS";
export const STORE_TOEDIT_TOTALS = "STORE_TOEDIT_TOTALS";
export const STORE_ORDER_TODELETE = "STORE_ORDER_TODELETE";
export const STORE_ORDER_TORESTORE = "STORE_ORDER_TORESTORE";
export const STORE_AVAILABLE_BALANCE = "STORE_AVAILABLE_BALANCE";
export const RESET_AVAILABLE_BALANCE = "RESET_AVAILABLE_BALANCE";
export const STORE_ACCOUNT_DEALERS = "STORE_ACCOUNT_DEALERS";

export const API_TEST_SDC_CONNECTION = "API_TEST_SDC_CONNECTION";
export const STORE_SDC_CONNECTION_STATUS = "STORE_SDC_CONNECTION_STATUS";
export const REMOVE_SDC_CONNECTION_STATUS = "REMOVE_SDC_CONNECTION_STATUS";

export const API_GET_ORDER_HISTORY = "API_GET_ORDER_HISTORY";
export const STORE_ORDER_HISTORY = "STORE_ORDER_HISTORY";

export const STORE_ALL_PERIODS = "STORE_ALL_PERIODS";
export const API_GET_ALL_PERIODS = "API_GET_ALL_PERIODS";
export const API_GET_PERIOD_BY_ORDER_ID = "API_GET_PERIOD_BY_ORDER_ID";
export const STORE_A_PERIOD = "STORE_A_PERIOD";

export const STORE_UPDATE_LOADING_STATUS = "STORE_UPDATE_LOADING_STATUS";

export const API_CREDIT_DEFICIT_OVERRIDE_ORDER =
  " API_CREDIT_DEFICIT_OVERRIDE_ORDER";

//Actions Creators

export const createStartLoadingAction = () => {
  return (dispatch) => {
    dispatch({
      type: STORE_UPDATE_LOADING_STATUS,
      skipLogging: true,
      payload: true,
    });
  };
};

export const createStopLoadingAction = () => {
  return (dispatch) => {
    dispatch({
      type: STORE_UPDATE_LOADING_STATUS,
      skipLogging: true,
      payload: false,
    });
  };
};

export const clearOrderAction = () => {
  console.log("order clear");
  return (dispatch) => {
    dispatch({
      type: STORE_ORDER,
      payload: null,
    });
  };
};
export const getOrderAction = (orderId) => {
  return (dispatch) => {
    dispatch({
      type: API_GET_ORDER,
      payload: {
        url: `/orders/${orderId}`,
        method: "get",
        //data: id,
        onSuccess: (data) => {
          return (dispatch) => dispatch({ type: STORE_ORDER, payload: data });
        },
        onError: (message) => {
          return (dispatch) => NotificationManager.error("unable to get order");
        },
      },
    });
  };
};

export const createGetOrderHistoryAction = (orderId) => {
  return (dispatch) => {
    dispatch({
      type: API_GET_ORDER_HISTORY,
      payload: {
        url: `/orders/GetOrderHistories?orderId=${orderId}`,
        method: "get",
        //data: id,
        onSuccess: storeOrderHistory,
        onError: errorFetchingHistory,
      },
    });
  };
};

const storeOrderHistory = (data) => {
  return (dispatch) => {
    dispatch({ type: STORE_ORDER_HISTORY, skipLogging: true, payload: data });
  };
};

const errorFetchingHistory = (message) => {
  return (dispatch) => {
    NotificationManager.error("Unable to fetch order history");
  };
};

/**
 * Fetch All orders by Status and Fitlered Text
 * @param {*} status
 * @param {*} searchText
 * @param {*} pageNumber
 * @param {*} pageSize
 * @param {*} sortBy
 * @returns List of Orders
 */
export const createGetOrdersByStatusAction = (
  status,
  searchText,
  pageNumber,
  pageSize,
  sortBy
) => {
  let skip = pageNumber * pageSize;
  return (dispatch) => {
    dispatch({
      type: API_GET_ALL_ORDERS,
      payload: {
        url: `/odata/OrdersOData?status=${status}&searchText=${searchText}&$count=true&$skip=${skip}&$top=${pageSize}&$expand=serviceStationAccount($select=ServiceStation,Operator;$expand=Region($select=RegionName)),OrderStatus($select=name),CreatedBy($select=FirstName,LastName,Deleted),OrderItems($select=PeriodId,Quantity,ProductId;$expand=Product($select=ListPrice)),&$select=id,deliverOn,addedDate,Note,RentalUtilisationFee,Vat&$orderby=${sortBy}`,
        method: "get",
        //data: id,
        onSuccess: storeAllOrders,
        onError: errorFetchingOrders,
      },
    });
  };
};

const calculateOrderTotal = (order) => {
  let orderItemTotal = 0;
  let rentalUtilisationTotal = 0;
  let grossTotal = 0;
  let newTotal = 0;
  if (order.OrderItems != null) {
    let items = order.OrderItems;
    let RentalUtilisationFee = order.RentalUtilisationFee;
    let vat = order.Vat;

    orderItemTotal = items
      .map((a) => a.Quantity * a.Product.ListPrice)
      .reduce((a, b) => a + b);
    rentalUtilisationTotal = items
      .map((a) => a.Quantity * RentalUtilisationFee ?? 0)
      .reduce((a, b) => a + b);
    grossTotal = orderItemTotal;
    newTotal = grossTotal * (1 + vat ?? 0) + rentalUtilisationTotal;
  }
  return newTotal;
};

const storeAllOrders = (response) => {
  let fetchedOrders = response.value.map((m) => ({
    id: m.Id,
    addedDate: m.AddedDate,
    createdBy: m.CreatedBy,
    deliverOn: m.DeliverOn,
    note: m.Note,
    orderItems: m.OrderItems.map((i) => ({
      product: {
        pricePerLitre: i.Product.ListPrice,
      },
      quantity: i.Quantity,
      productId: i.ProductId,
      periodId: i.PeriodId,
    })),
    orderStatus: {
      name: m.OrderStatus.Name,
    },
    rentalUtilisationFee: m.RentalUtilisationFee,
    serviceStationAccount: {
      name:
        m.ServiceStationAccount.ServiceStation == null ||
        m.ServiceStationAccount.ServiceStation.trim() == ""
          ? m.ServiceStationAccount.Operator
          : m.ServiceStationAccount.ServiceStation,
      zone: m.ServiceStationAccount?.Region?.RegionName
    },
    vat: m.Vat,
    total: calculateOrderTotal(m),
    createdByFullName:
      m.CreatedBy == null
        ? ""
        : m.CreatedBy.Deleted
        ? `${m.CreatedBy.FirstName} ${m.CreatedBy.LastName} <deleted>`
        : `${m.CreatedBy.FirstName} ${m.CreatedBy.LastName}`,
  }));

  let totalCount = response["@odata.count"];

  return (dispatch) => {
    dispatch({
      type: STORE_ALL_ORDERS,
      skipLogging: true,
      payload: { fetchedOrders, totalCount },
    });
  };
};

const errorFetchingOrders = (message) => {
  return (dispatch) => {
    NotificationManager.error("Unable to get orders");
  };
};

export const createGetRecentOrders = () => {
  return (dispatch) => {
    dispatch({
      type: API_GET_RECENT_ORDERS,
      payload: {
        url: "/orders/GetRecentOrders",
        method: "get",
        onSuccess: storeRecentOrders,
        onError: errorFetchingRecentOrders,
      },
    });
  };
};

const storeRecentOrders = (data) => {
  return (dispatch) => {
    dispatch({ type: STORE_RECENT_ORDERS, skipLogging: true, payload: data });
  };
};

const errorFetchingRecentOrders = (message) => {
  return (dispatch) => {};
};

export const createGetAvailableBalanceAction = (accountId, orderId) => {
  return (dispatch) => {
    var { deferredPromiseResolvers, dispatchPromise } = getDispatchPromise();
    dispatch({
      type: API_GET_AVAILABLE_BALANCE,
      payload: {
        url: `/orders/GetAvailableBalance?accountId=${accountId}&orderId=${
          orderId ? orderId : 0
        }`,
        method: "get",
        onSuccess: storeAvailableBalance,
        onError: errorFetchingOrders,
        deferredPromiseResolvers,
      },
    });
    return dispatchPromise;
  };
};

export const createOrder = (order, _history, onFinish) => {
  return (dispatch) => {
    dispatch({
      type: API_CREATE_ORDER,
      payload: {
        url: `/orders/CreateOrder`,
        method: "post",
        data: order,
        history: _history,
        // onSuccess: createOrderSuccess,
        // onError: errorCreatingOrder,
        onSuccess: (...args) => {
          onFinish()
          return createOrderSuccess(...args)
        },
        onError: (...args) => {
          onFinish()
          return errorCreatingOrder(...args)
        },
      },
    });
  };
};

export const deleteOrder = (orderId, userId, _history) => {
  return (dispatch) => {
    dispatch({
      type: API_DELETE_ORDER,
      payload: {
        url: `/orders/deleteOrder?orderId=${orderId}&userId=${userId}`,
        method: "post",
        history: _history,
        onSuccess: deleteOrderSuccess,
        onError: errorDeletingOrder,
      },
    });
  };
};

export const restoreOrder = (orderId, userId, _history) => {
  return (dispatch) => {
    dispatch({
      type: API_RESTORE_ORDER,
      payload: {
        url: `/orders/restoreOrder?orderId=${orderId}&userId=${userId}`,
        method: "post",
        history: _history,
        onSuccess: restoreOrderSuccess,
        onError: errorRestoringOrder,
      },
    });
  };
};

export const setOrderToDelete = (order) => {
  return (dispatch) => {
    dispatch({
      type: STORE_ORDER_TODELETE,
      payload: {
        order,
      },
    });
  };
};

export const setOrdertoRestore = (order) => {
  return (dispatch) => {
    dispatch({
      type: STORE_ORDER_TORESTORE,
      payload: {
        order,
      },
    });
  };
};

export const editOrder = (order, _history, onFinish) => {
  return (dispatch) => {
    dispatch({
      type: API_EDIT_ORDER,
      payload: {
        url: `/orders/EditOrder`,
        method: "post",
        data: order,
        history: _history,
        // onSuccess: editOrderSuccess,
        // onError: errorEditingOrder,
        onSuccess: (...args) => {
          onFinish()
          return editOrderSuccess(...args)
        },
        onError: (...args) => {
          onFinish()
          return errorEditingOrder(...args)
        },
      },
    });
  };
};

export const toggleCreditDeficitOverride = (orderId, _history) => {
  return (dispatch) => {
    dispatch({
      type: API_EDIT_ORDER, //API_CREDIT_DEFICIT_OVERRIDE_ORDER,
      payload: {
        url: `/orders/CreditDeficitOverride?orderId=${orderId}`,
        method: "post",
        history: _history,
        onSuccess: (data) => {
          return (dispatch) =>
            dispatch({
              type: API_GET_ORDER,
              payload: {
                url: `/orders/${orderId}`,
                method: "get",
                //data: id,
                onSuccess: (data) => {
                  return (dispatch) =>
                    dispatch({ type: STORE_ORDER, payload: data });
                },
                onError: (message) => {
                  return (dispatch) =>
                    NotificationManager.error("unable to get order");
                },
              },
            });
        },
        onError: (message) => {
          return (dispatch) => {
            NotificationManager.error("Unable to override order status");
          };
        },
      },
    });
  };
};



export const cancelOrder = (orderId, userId, order, _history) => {
	return (dispatch) => {
		dispatch({
			type: API_CANCEL_ORDER,
			payload: {
				url: `/orders/CancelOrder?orderId=${orderId}&userId=${userId}`,
				method: "post",
				data: order,
				history: _history,
				onSuccess: cancelOrderSuccess,
				onError: errorCancellingOrder,
			},
		});
	};
};

//calculate the totals for an
export const calculateEditOrderTotals = (order) => {
	return (dispatch, getState) => {
		let vat = 1.125;
		let toEdit = new Object();
		const { customer } = getState();
		const { serviceStations } = getState().order;
		const { product } = getState();

		let index = serviceStations.findIndex(
			(item) => item.id === order.serviceStationId
		);

		toEdit.selectedAccountIndex = index;
		toEdit.selectedServiceStationIndex = index;
		if (customer.accounts) {
			order.account = customer.accounts[index];
		}

		toEdit.totalQty = 0;

		if (order.orderItems !== null) {
			toEdit.totalQty = order.orderItems.reduce((x, oi) => x + +oi.quantity, 0);
			if (toEdit.totalQty === NaN) toEdit.totalQty = 0;
		}

		toEdit.totalRUF = 0;

		if (order.account) {
			toEdit.totalRUF = toEdit.totalQty * order.account.rentalUtilisationFee;
		}

		toEdit.totalCost = 0;
		if (product.products && product.products.length > 0) {
			if (order.orderItems && order.orderItems.length > 0) {
				order.orderItems.forEach((orderItem) => {
					toEdit.totalCost +=
						orderItem.quantity *
						product.products.find((p) => p.id === orderItem.productId)
							.pricePerLitre *
						vat;
				});
				toEdit.totalCost += toEdit.totalRUF;
			}
		}
		toEdit.totalCost += 0;
		dispatch({
			type: STORE_TOEDIT_TOTALS,
			payload: { toEdit },
		});
	};
};

const errorFetchingServiceStations = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to get service stations");
	};
};

const errorFetchingDeletedOrders = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to get archived orders");
	};
};

const errorFetchingProducts = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to get products");
	};
};

const errorCreatingOrder = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to create order");
	};
};

const errorDeletingOrder = (message) => {
  return (dispatch) => {
    NotificationManager.error("Unable to delete order");
  };
};

const errorEditingOrder = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to edit order");
	};
};

const errorRestoringOrder = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to restore order");
	};
};

const createOrderSuccess = (data, history) => {
	return (dispatch) => {
		history.push("order-list");
		NotificationManager.success("Order Added Successfully");
	};
};

const editOrderSuccess = (data, history) => {
	return (dispatch) => {
		history.push("order-list");
		NotificationManager.success("Order Edited Successfully");
	};
};

const cancelOrderSuccess = (data, history) => {
	return (dispatch) => {
		history.push("order-list");
		NotificationManager.success("Order Cancelled Successfully");
	};
};

const errorCancellingOrder = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to cancel order");
	};
};

const errorApprovingOrder = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to approve order");
	};
};

const deleteOrderSuccess = (data, history) => {
	return (dispatch) => {
		dispatch({
			type: REMOVE_ORDER,
			payload: { orderId: data.id },
		});
		NotificationManager.success("Order Archived Successfully");
	};
};

const restoreOrderSuccess = (data, history) => {
	return (dispatch) => {
		dispatch({
			type: REMOVE_DELETED_ORDER,
			payload: { deletedOrderId: data.id },
		});
		NotificationManager.success("Order Restored Successfully");
	};
};

export const createGetOrderStatusesAction = () => {
	return (dispatch) => {
		dispatch({
			type: API_GET_ALL_ORDER_STATUSES,
			payload: {
				url: "/orders/GetOrderStatuses",
				method: "get",
				onSuccess: storeAllOrderStatuses,
				onError: errorGettingOrderStatuses,
			},
		});
	};
};

export const createGetServiceStationsAction = () => {
	return (dispatch) => {
		dispatch({
			type: API_GET_ALL_SERVICE_STATIONS,
			payload: {
				url: "/orders/GetServiceStations",
				method: "get",
				onSuccess: storeAllServiceStations,
				onError: errorFetchingServiceStations,
			},
		});
	};
};

/**
 * Fetch all deleted (Archived) orders.
 * @param {*} searchText 
 * @param {*} pageNumber 
 * @param {*} pageSize 
 * @param {*} sortBy 
 * @returns All Deleted orders
 */
export const createGetDeletedOrdersAction = (searchText,pageNumber,pageSize,sortBy) => {
	let skip = pageNumber * pageSize;
	return (dispatch) => {
		dispatch({
			type: API_GET_DELETED_ORDERS,
			payload: {
				url: `/odata/DeletedOrdersOData?searchText=${searchText}&$count=true&$skip=${skip}&$top=${pageSize}&$orderby=${sortBy}&$expand=serviceStationAccount($select=ServiceStation,Operator;$expand=Region($select=RegionName)),OrderStatus($select=name),CreatedBy($select=FirstName,LastName,Deleted),&$select=id,deliverOn,addedDate,Note,RentalUtilisationFee,Vat`,
				method: "get",
				onSuccess: storeDeletedOrders,
				onError: errorFetchingDeletedOrders,
			},
		});
	};
};

export const createGetProductsAction = () => {
	return (dispatch) => {
		dispatch({
			type: API_GET_ALL_PRODUCTS,
			payload: {
				url: "/orders/GetProducts",
				method: "get",
				onSuccess: storeAllProducts,
				onError: errorFetchingProducts,
			},
		});
	};
};

export const createGetDealersByAccountAction = (accountId) => {
	return (dispatch) => {
		dispatch({
			type: API_GET_DEALERS_BY_ACCOUNT,
			payload: {
				url: `/users/GetDealersByAccount?accountId=${accountId}`,
				method: "get",
				onSuccess: storeAccountDealers,
				onError: errorFetchingProducts,
			},
		});
	};
};

const storeAccountDealers = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_ACCOUNT_DEALERS,
			skipLogging: true,
			payload: data,
		});
	};
};

const storeAllOrderStatuses = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_ALL_ORDER_STATUSES,
			skipLogging: true,
			payload: data,
		});
	};
};

const storeAvailableBalance = (data, history, deferredPromiseResolvers) => {
  return (dispatch) => {
    dispatch({
      type: STORE_AVAILABLE_BALANCE,
      skipLogging: true,
      payload: data,
    });
    resolveDispatchPromise(deferredPromiseResolvers);
  };
};

const storeAllServiceStations = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_ALL_SERVICE_STATIONS,
			skipLogging: true,
			payload: data,
		});
	};
};

const storeDeletedOrders = (response) => {

let fetchedOrders = response.value.map((m) => ({
      id: m.Id,
      addedDate: m.AddedDate,
      createdBy: m.CreatedBy,
      deliverOn: m.DeliverOn,
      note: m.Note,
	  
      orderStatus: {
        name: m.OrderStatus.Name,
      },
      rentalUtilisationFee: m.RentalUtilisationFee,
      serviceStationAccount: {
        name:
          m.ServiceStationAccount.ServiceStation == null ||
          m.ServiceStationAccount.ServiceStation.trim() == ""
            ? m.ServiceStationAccount.Operator
            : m.ServiceStationAccount.ServiceStation,
        zone: m.ServiceStationAccount?.Region?.RegionName
      },
      vat: m.Vat,
      total: calculateOrderTotal(m),
      createdByFullName:
        m.CreatedBy == null
          ? ""
          : m.CreatedBy.Deleted
          ? `${m.CreatedBy.FirstName} ${m.CreatedBy.LastName} <deleted>`
          : `${m.CreatedBy.FirstName} ${m.CreatedBy.LastName}`,
    }));

	let totalCount = response["@odata.count"]

	return (dispatch) => {
		dispatch({
			type: STORE_DELETED_ORDERS,
			skipLogging: true,
			payload: {fetchedOrders,totalCount},
		});
	};
};

const storeAllProducts = (data) => {
	return (dispatch) => {
		dispatch({ type: STORE_ALL_PRODUCTS, skipLogging: true, payload: data });
	};
};

const errorGettingOrderStatuses = (message) => {
	return (dispatch) => {
		NotificationManager.error("Unable to get order statuses!");
	};
};

export const createGetSDCConnectionAction = () => {
	return (dispatch) => {
		dispatch({
			type: API_TEST_SDC_CONNECTION,
			payload: {
				url: "/accounts/activeconnection",
				method: "get",
				onSuccess: sdcConnectionActiveHandler,
				onError: sdcConnectionInactiveHandler,
			},
		});
	};
};

const sdcConnectionActiveHandler = (data) => {
	return (dispatch) => {
		dispatch({
			type: STORE_SDC_CONNECTION_STATUS,
			skipLogging: true,
            payload: data,
		});
	};
};

const sdcConnectionInactiveHandler = (message) => {
	return (dispatch) => {
		dispatch({
			type: STORE_SDC_CONNECTION_STATUS,
			skipLogging: true,
			payload: false,
		});
	};
};

export const createSDCConnectionAlertHideAction = () => {
	return (dispatch) => {
		dispatch({ type: REMOVE_SDC_CONNECTION_STATUS });
	};
};

export const createResetAvailableBalance = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_AVAILABLE_BALANCE,
      skipLogging: true,
    });
  };
};

export const createGetPeriodsAction = () => {
  return (dispatch) => {
    dispatch({
      type: API_GET_ALL_PERIODS,
      payload: {
        url: "/orders/GetPeriods",
        method: "get",
        onSuccess: storeAllPeriods,
        onError: errorFetchingPeriods,
      },
    });
  };
};

const storeAllPeriods = (data) => {
  return (dispatch) => {
    dispatch({ type: STORE_ALL_PERIODS, skipLogging: true, payload: data });
  };
};

const errorFetchingPeriods = (message) => {
  return (dispatch) => {
    NotificationManager.error("Unable to get periods!");
  };
};

export const createGetOrderPeriod = (orderId) => {
  return (dispatch) => {
    dispatch({
      type: API_GET_PERIOD_BY_ORDER_ID,
      payload: {
        url: `/orders/GetPeriod?orderId=${orderId}`,
        method: "get",
        onSuccess: storeAPeriod,
        onError: errorFetchingAPeriod,
      },
    });
  };
};

const storeAPeriod = (data) => {
  return (dispatch) => {
    dispatch({ type: STORE_A_PERIOD, skipLogging: true, payload: data });
  };
};

const errorFetchingAPeriod = (message) => {
  return (dispatch) => {};
};

/**
 * initial order list state
 */
const INIT_ORDER_LIST_STATE = {
  orders: [],
  recentOrders: [],
  statuses: [],
  products: [],
  serviceStations: [],
  dealers: null,
  toEdit: null,
  availableBalance: null,
  orderTotalCount: 0,
  ordersPerPage: 10,
  orderCurrentPage: 1,
  loading: false,
  deletedOrders: [],
  sdcConnection: {
    sdcConnectionActive: false,
    alertVisible: false,
  },
  selectedPeriod: null,
  totalDeletedOrder:0
};

export default (state = INIT_ORDER_LIST_STATE, action) => {
  switch (action.type) {
    case STORE_UPDATE_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case STORE_ALL_ORDERS:
      return { ...state, orders: action.payload.fetchedOrders, orderTotalCount:action.payload.totalCount };

    case STORE_RECENT_ORDERS:
      return { ...state, recentOrders: action.payload };

    case STORE_ALL_ORDER_STATUSES:
      return { ...state, statuses: action.payload };

    case STORE_ALL_SERVICE_STATIONS:
      return { ...state, serviceStations: action.payload };

    case REMOVE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => {
          return order.id !== action.payload.orderId;
        }),
        alertDeleted: true,
      };

    case REMOVE_DELETED_ORDER:
      return {
        ...state,
        deletedOrders: state.deletedOrders.filter((deletedOrders) => {
          return deletedOrders.id !== action.payload.deletedOrderId;
        }),
        alertDeleted: true,
      };

    case STORE_ALL_PRODUCTS:
      return { ...state, products: action.payload };

    case STORE_ACCOUNT_DEALERS:
      return { ...state, dealers: action.payload };

    case STORE_TOEDIT_TOTALS:
      return { ...state, toEdit: action.payload };

    case STORE_DELETED_ORDERS:
      return { ...state, deletedOrders: action.payload.fetchedOrders, totalDeletedOrder: action.payload.totalCount };

    case STORE_ORDER_TODELETE:
      return { ...state, toDelete: action.payload };

    case STORE_ORDER_TORESTORE:
      return { ...state, toRestore: action.payload };

    case STORE_AVAILABLE_BALANCE:
      return { ...state, availableBalance: action.payload };

    case STORE_ALL_PERIODS:
      return { ...state, periods: action.payload };

    case STORE_SDC_CONNECTION_STATUS:
      return {
        ...state,
        sdcConnection: {
          sdcConnectionActive: action.payload,
          alertVisible: true,
        },
      };

    case REMOVE_SDC_CONNECTION_STATUS:
      return {
        ...state,
        sdcConnection: {
          sdcConnectionActive: state.sdcConnection.sdcConnectionActive,
          alertVisible: false,
        },
      };

    case STORE_ORDER_HISTORY:
      return { ...state, orderHistory: action.payload };

    case RESET_AVAILABLE_BALANCE:
      return { ...state, availableBalance: null };

    case STORE_A_PERIOD:
      return { ...state, period: action.payload };

    case STORE_ORDER:
      return { ...state, orderDetails: action.payload };

    default:
      return { ...state };
  }
};
