//Action Types
import { NotificationManager } from "react-notifications";

export const API_GET_ALL_PRODUCTS = "API_GET_ALL_PRODUCTS";
export const STORE_ALL_PRODUCTS = "STORE_ALL_PRODUCTS";

//Actions Creators

const errorFetchingProducts = (message) => {
  return (dispatch) => {
    dispatch({ type: HANDLE_ERROR, payload: { message: message } });
    NotificationManager.error("Unable to get products");
  };
};

export const createGetProductsAction = () => {
  return (dispatch) => {
    dispatch({
      type: API_GET_ALL_PRODUCTS,
      payload: {
        url: "/orders/GetProducts",
        method: "get",
        onSuccess: storeAllProducts,
        onError: errorFetchingProducts,
      },
    });
  };
};

const storeAllProducts = (data) => {
  return (dispatch) => {
    dispatch({ type: STORE_ALL_PRODUCTS, payload: data });
  };
};

/**
 * initial product list state
 */
const INIT_PRODUCT_LIST_STATE = {
  products: [],
  loading: false,
};

export default (state = INIT_PRODUCT_LIST_STATE, action) => {
  switch (action.type) {
    case STORE_ALL_PRODUCTS:
      return { ...state, products: action.payload, loading: false };

    default:
      return { ...state, loading: false };
  }
};
