//Action Types
import { NotificationManager } from "react-notifications";

export const API_GET_LOGS = "API_GET_LOGS";
export const STORE_ALL_LOGS = "STORE_ALL_LOGS";


//Actions Creators
export const createGetAllLogsAction = (page, sortOrder, rowsPerPage) => {
  return (dispatch) => {
    dispatch({
      type: API_GET_LOGS,
      payload: {
        url: "/logger/getlogs",
        method: "POST",
        data: {
          sortField: sortOrder.name,
          sortDirection: sortOrder.direction,
          rowsPerPage: rowsPerPage,
          pageNumber: page
        },
        onSuccess: processAllLog,
        onError: errorGettingLogs,
      },
    });
  };
};

const processAllLog = (data) => {
  return (dispatch) => {
      dispatch({ type: STORE_ALL_LOGS, skipLogging: true, payload: data});
  };
};


const errorGettingLogs = (message) => {
  return (dispatch) => {
    //notifications.map((notification) => {NotificationManager.info(notification.message);}) 
  };
};

const INIT_LOG_LIST_STATE = {
    logs: [],
    total: 0,
    pageNumber: 1,
    rowsPerPage: 10,
    sortField: "id",
    sortDirection: "asc",
    isLoading: false
};

export default (state = INIT_LOG_LIST_STATE, action) => {
  
    switch (action.type) {

        case STORE_ALL_LOGS:
            return { 
              ...state, 
              logs: action.payload.logs, 
              total: action.payload.total, 
              pageNumber: action.payload.pageNumber, 
              rowsPerPage: action.payload, 
              sortField:action.payload.sortField, 
              sortDirection: action.payload.sortDirection, 
              isLoading: false 
            };

        default:
            return { ...INIT_LOG_LIST_STATE };
  }

};
