/**
 * User Block Component
 */
import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge } from "reactstrap";
import { NotificationManager } from "react-notifications";

// components
import SupportPage from "../Support/Support";

// redux action
import { createLogoutAction, createRefreshLoggedInUser } from "../../store/authentication";

// intl messages
import IntlMessages from "Util/IntlMessages";

class UserBlock extends Component {
  state = {
    userDropdownMenu: false,
    isSupportModal: false,
  };

  componentDidMount()
  {
	  if(!this.props?.user?.id)
	  {
		  this.props.createRefreshLoggedInUser();
	  }
  }

  /**
   * Logout User
   */
  logoutUser(e) {
    e.preventDefault();
    this.props.logout(this.props.history, this.props.user?? {username: ''});
  }

  /**
   * Toggle User Dropdown Menu
   */
  toggleUserDropdownMenu() {
    this.setState({ userDropdownMenu: !this.state.userDropdownMenu });
  }

  /**
   * Open Support Modal
   */
  openSupportModal() {
    this.setState({ isSupportModal: true });
  }

  /**
   * On Close Support Page
   */
  onCloseSupportPage() {
    this.setState({ isSupportModal: false });
  }

  /**
   * On Submit Support Page
   */
  onSubmitSupport() {
    this.setState({ isSupportModal: false });
    NotificationManager.success("Message has been sent successfully!");
  }


  render() {
    const { name="", email = "", avatar = "profile.jpg", id } = this.props;
     const {user} = this.props;
     if(user)
      var fullName = name;
  
    return (
			<div className="top-sidebar">
				<div className="sidebar-user-block">
					<Dropdown
						isOpen={this.state.userDropdownMenu}
						toggle={() => this.toggleUserDropdownMenu()}
						className="rct-dropdown"
					>
						<DropdownToggle tag="div" className="d-flex align-items-center">
							<div className="user-profile">
								<img
									src={require(`Assets/avatars/${avatar}`)}
									alt="user profile"
									className="img-fluid rounded-circle"
									width="50"
									height="100"
								/>
							</div>
							<div className="user-info">
								<span className="user-name ml-4">{fullName}</span>
								<i className="zmdi zmdi-chevron-down dropdown-icon mx-4"></i>
							</div>
						</DropdownToggle>
						<DropdownMenu>
							<ul className="list-unstyled mb-0">
								<li className="p-15 border-bottom user-profile-top bg-primary rounded-top">
									<p className="text-white mb-0 fs-14">{fullName}</p>
									<span className="text-white fs-14">{email}</span>
								</li>
								<li>
									<Link
										to={{
											pathname: "/app/users/user-profile/my-profile",

											state: {
												toEdit: id,
												activeTab: 1,
												isCurrentUser: true,
											},
										}}
									>
										<i className="zmdi zmdi-account text-primary mr-3"></i>
										<span>
											<IntlMessages id="widgets.profile" />
										</span>
									</Link>
								</li>

								<li className="border-top">
									<a href="#" onClick={(e) => this.logoutUser(e)}>
										<i className="zmdi zmdi-power text-danger mr-3"></i>
										<span>
											<IntlMessages id="widgets.logOut" />
										</span>
									</a>
								</li>
							</ul>
						</DropdownMenu>
					</Dropdown>
				</div>
				<SupportPage
					isOpen={this.state.isSupportModal}
					onCloseSupportPage={() => this.onCloseSupportPage()}
					onSubmit={() => this.onSubmitSupport()}
				/>
			</div>
		);
  }
}

// map state to props
const mapStateToProps = ({ authentication}) => {
  const {user}=authentication;
  const { id, name, avatar, email } = authentication.user;
  return { id, name, avatar, email, user };
};

export default withRouter(
  connect(mapStateToProps, {
    logout: createLogoutAction,
	createRefreshLoggedInUser
  })(UserBlock)
);


 