const keep = -100000;

export const LOGGER_API_LOC = 'api';
export const LOGGER_COMPONENT_LOC = 'component';

export const LocalLogger = (_data, _location = '') => {
    let logs = JSON.parse(localStorage.getItem('Logs')) || [];
    const d = new Date();
    logs.push({
        "key": d.getTime(),
        "date": d.toLocaleString(),
        "log": _data,
        "location": _location
    });
    localStorage.setItem('Logs', JSON.stringify(logs.slice(keep)));
}