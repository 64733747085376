import { combineReducers } from "redux";

import adminReducer from "./admin";
import authenticationReducer from "./authentication";
import auditlog from "./auditlog";
import contractorReducer from "./contractor";
import order from "./order";
import product from "./product";
import customer from "./customer";
import routeReducer from "./route";
import scheduleReducer from "./schedule";
import settingReducer from "./setting";
import userReducer from "./user";
import vehicleReducer from "./vehicle";
import serviceStationReducer from "./servicestation";
import settings from "../reducers/settings";
import emailAppReducer from "../reducers/EmailAppReducer";
import sidebarReducer from "../reducers/SidebarReducer";
import notificationReducer from "./notification";






const reducers = combineReducers({
  //admin: adminReducer,
  authentication: authenticationReducer,
  //contractor: contractorReducer,
  order: order,
  product: product,
  customer: customer,
  schedule: scheduleReducer,
  applicationSettings: settingReducer,
  user: userReducer,
  vehicle: vehicleReducer,
  route: routeReducer,
  notification: notificationReducer,
  settings,
  emailApp: emailAppReducer,
  sidebar: sidebarReducer,
  auditlog: auditlog,
  serviceStation: serviceStationReducer,
  //authUser: authUserReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'PROCESS_LOGOUT') {
    state = undefined
    //dispatch({ type: LOGOUT_SUCCESS, skipLogging: true });
  }
  return reducers(state, action) 
} 

export default rootReducer;
