// sidebar nav links
export default {
	category1: [
		{
			menu_title: "sidebar.admin",
			menu_icon: "zmdi zmdi-settings-square",
			type_multi: null,
			new_item: false,
			path: "/app/admin",
			child_routes: null,
			claims: ["Admin"],
		},
		{
			menu_title: "sidebar.orderList",
			menu_icon: "zmdi zmdi-collection-text",
			type_multi: null,
			new_item: false,
			path: "/app/orders",
			child_routes: null,
			claims: [
				"Admin",
				"Dealer",
				"Supervisor",
				"Clerk",
				"Manager",
				"Operator",
				"SalesRep",
				"Contractor",
				"Driver",
			],
		},
		{
			menu_title: "sidebar.scheduleDaily",
			menu_icon: "zmdi zmdi-calendar",
			type_multi: null,
			new_item: false,
			path: "/app/schedules/daily",
			child_routes: null,
			claims: ["Admin", "Supervisor", "Clerk", "Manager", "SalesRep"],
		},
		/*{
			menu_title: "sidebar.schedules",
			menu_icon: "zmdi zmdi-calendar",
			type_multi: null,
			new_item: false,
			path: "/app/schedules",
			child_routes: [
				{
					menu_title: "sidebar.routes",
					menu_icon: "zmdi zmdi-truck",
					type_multi: null,
					new_item: false,
					path: "/app/schedules/routes",
					child_routes: null,
					claims: ["Admin", "Supervisor", "Clerk", "Manager", "SalesRep"],
					key: 4.1,
				},
				{
					menu_title: "sidebar.scheduleList",
					menu_icon: "zmdi zmdi-schedule-list",
					type_multi: null,
					new_item: false,
					path: "/app/schedules/list",
					child_routes: null,
					claims: ["Admin", "Supervisor", "Clerk", "Manager", "SalesRep"],
					key: 4.2,
				},
				{
					menu_title: "sidebar.scheduleDaily",
					menu_icon: "zmdi zmdi-schedule-daily",
					type_multi: null,
					new_item: false,
					path: "/app/schedules/daily",
					child_routes: null,
					claims: ["Admin", "Supervisor", "Clerk", "Manager", "SalesRep"],
					key: 4.3,
				},
			],
			claims: ["Admin", "Supervisor", "Clerk", "Manager", "SalesRep"],
		},*/
		{
			menu_title: "sidebar.serviceStationList",
			menu_icon: "zmdi zmdi-accounts-list",
			type_multi: null,
			new_item: false,
			path: "/app/servicestations",
			child_routes: null,
			claims: ["Admin"],
		},
		{
			menu_title: "sidebar.userList",
			menu_icon: "zmdi zmdi-accounts-list",
			type_multi: null,
			new_item: false,
			path: "/app/users",
			child_routes: null,
			claims: ["Admin"],
		},
		{
			menu_title: "sidebar.vehicleList",
			menu_icon: "zmdi zmdi-accounts-list",
			type_multi: null,
			new_item: false,
			path: "/app/vehicles",
			child_routes: null,
			claims: ["Admin"],
		},
		/*{
      menu_title: "sidebar.auditlogs",
      menu_icon: "zmdi zmdi-calendar-note",
      type_multi: null,
      new_item: false,
      path: "/app/auditlogs",
      child_routes: null,
      claims: ["Admin"]
    },*/
	],
	category2: [],
	category3: [],
	category4: [],
	category5: [],
	category6: [],
};
