//Action Types
import { NotificationManager } from "react-notifications";

export const API_GET_ALL_NOTIFICATIONS = "API_GET_ALL_NOTIFICATIONS";

export const STORE_APPLICATION_NOTIFICATION = "STORE_APPLICATION_NOTIFICATION";

//Actions Creators
export const createGetAllNotificationsAction = (userId, history) => {
  
  return (dispatch) => {
    dispatch({
      type: API_GET_ALL_NOTIFICATIONS,
      payload: {
        url: `/notifications?userId=${userId}`,
        method: "get",
        onSuccess: processAllNotifications,
        onError: errorFetchingNotifications,
        history: history
      },
    });
  };
};


const processAllNotifications = (notifications) => {
  let recentNotifications=[];
  if(notifications && notifications.length > 0)
    notifications.map((notification) => 
    {
      NotificationManager.info(notification);
      //var today = new Date();
      //var date =(today.getMonth()+1)+'-'+today.getDate()+' '+ today.getHours() + ":" + today.getMinutes();
      recentNotifications.push( {text:notification, date:Date.now()});
    });

  return (dispatch) => {
    dispatch({
      type: STORE_APPLICATION_NOTIFICATION,
      skipLogging: true,
      payload: recentNotifications
    });
  };
};


const errorFetchingNotifications = (notifications, history) => {
  return (dispatch) => {
    if(notifications.includes("401"))
    {
      if(history){
				//NotificationManager.success("Session expired!");
				dispatch({ type: "PROCESS_LOGOUT" });
				history.push("/signin");
			}
    }
  };
};

 
const INIT_NOTIFICATION_STATE = {
  recentNotifications:[],
  unread:0
};


export default (state = INIT_NOTIFICATION_STATE , action) => {
  switch (action.type) {
      case STORE_APPLICATION_NOTIFICATION:
          return { ...state, unread:action.payload.length, recentNotifications: (action.payload.concat(state.recentNotifications)).slice(0,10)  };
      default:
          return { ...state};
  }
};
