
export const API_GET_ROUTES = "API_GET_ROUTES";
export const STORE_ROUTES = "STORE_ROUTES";


//Actions Creators
export const createGetRoutesAction = (userName) => {
  return (dispatch) => {
    dispatch({
      type: API_GET_ROUTES,
      payload: {
        url: `/routes/foruser?userName=${userName}`,
        method: "get",
        onSuccess: storeRoutes,
        onError: errorGettingRoutes,
      },
    });
  };
};


const storeRoutes = (data, history) => {
  return (dispatch) => {
    dispatch({ type: STORE_ROUTES, skipLogging: true, payload: data });
  };
};


const errorGettingRoutes = (message) => {
  return (dispatch) => {
    NotificationManager.error("Unable to run the scheduler");
  };
};


/**
 * initial order list state
 */
const INIT_SCHEDULE_STATE = {
    routes : [],
};


export default (state = INIT_SCHEDULE_STATE, action) => {
  switch (action.type) {
    case STORE_ROUTES:
      return { routes : action.payload };

    default:
      return { ...state };
  }
};