import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import axios from "axios";
import AppConfig from "../constants/AppConfig";


class PrivateRoute extends Component{

    constructor(props)
    {
      super(props);
    }

    logRequest(user, props){
        const data = { 
          username: user.username, 
          role: user.role, 
          timeoccurred: new Date().toLocaleString(),
          requesturl: props.match.url,
        };

       axios.request({
        baseURL: AppConfig.apiBaseURL,
        url: '/logger/logrequest',
        method: 'POST',
        data,
        withCredentials: true,
        
      });

      return 1;
    }

    isLoggedIn(user, loggedIn) {
      return user && loggedIn;
    }

    hasClaim(userClaims, claims)
    {
      if(!claims) return true;
      if(!userClaims) return false;
      const normalizedUserClaims = Array.isArray(userClaims) ? userClaims.map((claim) => {return claim.toLowerCase()}) : userClaims.split().map((claim) => {return claim.toLowerCase()});
      const normalizedClaims = Array.isArray(claims) ? claims.map((claim) => {return claim.toLowerCase()}) : claims.split().map((claim) => {return claim.toLowerCase()});
      if(normalizedClaims.length === 0) return true;
      return normalizedUserClaims.some(item => normalizedClaims.includes(item));
    }


    render()
    {
        const { children, routecomponent, user, loggedIn, claims, ...rest } = this.props;

        if(!routecomponent)
        return(
          
         <Fragment>
            
             {this.isLoggedIn(user, loggedIn)? 
                  this.hasClaim(user.role, claims)? 
                    children 
                    : 
                    <Redirect to={{ pathname: "/app/dashboard/orders" }} /> 
                : 
                <Redirect to={{ pathname: "/signin" }} />}
         </Fragment>
         
        );
        else
        {
          return(
          
         <Fragment>
            
             {this.isLoggedIn(user, loggedIn)? 
                  this.hasClaim(user.role, claims)? 
                    React.createElement(routecomponent, rest)
                    : 
                    <Redirect to={{ pathname: "/app/dashboard/orders" }} /> 
                : 
                <Redirect to={{ pathname: "/signin" }} />}
         </Fragment>
         
        );
        }
    }
}

PrivateRoute.propTypes = {
  children: PropTypes.element,
  claims: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  routecomponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ])
}

// map state to props
const mapStateToProps = ({ authentication }) => {
  const { user,loggedIn } = authentication;
  return { user, loggedIn };
};

export default connect(mapStateToProps, {})(PrivateRoute);