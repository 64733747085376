/**
 * Vehicles Routes
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PrivateRoute from "../../container/PrivateRoute"
// async components

import {
    AsyncVehiclesListComponent , AsyncCreateVehicleComponent, AsyncEditVehicleComponent//, AsyncVehicleProfileComponent
} from 'Components/AsyncComponent/AsyncComponent';
 

const Vehicles = ({ match }) => (
    <div className="content-wrapper">
        <Helmet>
            <title>Vehicles</title>
            <meta name="description" content="Vehicle List" />
        </Helmet>

        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/vehicle-list`} />
            <Route path={`${match.url}/create-vehicle`} render={(props) => <PrivateRoute {...props} claims={["Admin"]} routecomponent={AsyncCreateVehicleComponent} />} />
            <Route path={`${match.url}/vehicle-list`} render={(props) => <PrivateRoute {...props} claims={["Admin"]} routecomponent={AsyncVehiclesListComponent} />} />      
            <Route path={`${match.url}/:toEdit`}   render={(props) => <PrivateRoute {...props}   routecomponent={AsyncEditVehicleComponent } />} />
        </Switch>
    </div>
);


export default Vehicles;
