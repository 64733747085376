/**
 * Notification Component
 */
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { Badge } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { createGetAllNotificationsAction } from "../../store/notification";
import { connect } from "react-redux";
// api
import api from 'Api';

// intl messages
import IntlMessages from 'Util/IntlMessages';

class Notifications extends Component {

	state = {
		notifications: [],
		unread: null
	}

	componentDidMount() {
		 
	}

	componentDidUpdate() {
		if (this.state.notifications[0] != this.props.notifications[0]) { //if the top (most recent) notification is new
			this.state.notifications = this.props.notifications;
			this.state.unread += this.props.unread; //update number of unread
		}
	}

	render() {
		const { notifications, user, unread } = this.props;
		notifications.forEach(element => element.timeSince = Math.abs(Date.now() - element.date) / 36e5); //calculate hours since the timestamp of the notification

		return (
			<UncontrolledDropdown nav className="list-inline-item notification-dropdown" onClick={() => this.setState({ unread: 0 })}>
				<DropdownToggle nav className="p-0" onClick={() => this.setState({ unread: 0 })}>
					{(notifications[0]) && this.state.unread != 0 &&
						<Tooltip title="Notifications" placement="bottom">
							<IconButton className="shake text-light" aria-label="bell" onClick={() => this.setState({ unread: 0 })}>
								<i className="zmdi zmdi-notifications-active"></i>
								{(this.state.unread > 10) && <Badge color="dark" className="badge-xs badge-top-right rct-notify">10+</Badge>}
								{(this.state.unread <= 10) && <Badge color="dark" className="badge-xs badge-top-right rct-notify">{this.state.unread}</Badge>}
							</IconButton>
						</Tooltip>}
					{(!notifications[0] || this.state.unread == 0) &&
						<Tooltip title="Notifications" placement="bottom">
							<IconButton className="text-light" aria-label="bell">
								<i className="zmdi zmdi-notifications-active"></i>
							</IconButton>
						</Tooltip>}
				</DropdownToggle>
				<DropdownMenu right>
					<div className="dropdown-content">
						<div className="dropdown-top d-flex justify-content-between rounded-top bg-dark">
							<span className="text-white font-weight-bold">
								<IntlMessages id="widgets.recentNotifications" />
							</span>

							{notifications[0] && <Badge color="warning">NEW</Badge>}
						</div>


						<Scrollbars className="rct-scroll " autoHeight autoHeightMin={100} autoHeightMax={280}>
							<ul className="list-unstyled dropdown-list">

								{notifications && notifications[0] && notifications.map((notification, key) => (
									<li key={key}>
										<div className="media">
											<div className="mr-10">

												<img src={require(`Assets/avatars/${user.avatar}`)} alt="user profile" className="media-object rounded-circle" width="50" height="50" />
											</div>
											<div className="media-body pt-5">
												<div className="justify-content-between">
													<h5 className="mb-5 text-primary">{notification.text}</h5>
													{notification.timeSince < 1 && <span className="text-muted fs-12">{Math.round(notification.timeSince * 60)} mins ago</span>}
													{notification.timeSince >= 1 && <span className="text-muted fs-12">{Math.round(notification.timeSince)} hours ago</span>}
												</div>


											</div>
										</div>
									</li>
								))}
								{notifications.length == 0 &&
									<li >
										<div className="media">

											<div className="media-body pt-5">
												<div className="d-flex justify-content-between">
													<h5 className="mb-5 text-primary">No new notifications</h5>
													<span className="text-muted fs-12">Today</span>
												</div>
											</div>
										</div>
									</li>
								}
							</ul>
						</Scrollbars>
					</div>

				</DropdownMenu>
			</UncontrolledDropdown>
		);
	}
}

function mapStateToProps(state) {
	const notifications = state.notification.recentNotifications || [];
	const unread = state.notification.unread;
	const user = state.authentication.user;
	return { notifications, user, unread };
}



export default connect(mapStateToProps, { createGetAllNotificationsAction })(
	Notifications
);

