/**
 * Users Routes
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PrivateRoute from "../../container/PrivateRoute"
// async components

import {
    AsyncAdminSettingsComponent
} from 'Components/AsyncComponent/AsyncComponent';
 

const Admin = ({ match }) => (
    <div className="content-wrapper">
        <Helmet>
            <title>Admin Settings</title>
            <meta name="description" content="Admin Settings" />
        </Helmet>

        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/settings`} />
            <Route path={`${match.url}/settings`} render={(props) => <PrivateRoute {...props} claims={["Admin"]} routecomponent={AsyncAdminSettingsComponent} />} />
        </Switch>
    </div>
);


export default Admin;
